import { Action, combineReducers } from 'redux';
import {
  CARRYOUT_SEARCH_SUCCESS,
  DELIVERY_SEARCH_SUCCESS,
  SELECT_STORE
} from './actionTypes';
import {
  CarryoutSearchSuccess,
  DeliverySearchSuccess,
  StoreInfo
} from './localizeActions';

// allow non-default export even though this is the only one right now;
// do this because we do anticipate (probably) adding more reducers here
/* eslint-disable import/prefer-default-export */
const stores = (state = [], action: Action) => {
  switch (action.type) {
    case DELIVERY_SEARCH_SUCCESS: {
      return (action as DeliverySearchSuccess).deliverySearchResults.stores || [];
    }
    case CARRYOUT_SEARCH_SUCCESS: {
      return (action as CarryoutSearchSuccess).carryoutSearchResults;
    }
    default: {
      return state;
    }
  }
};

const localizedStore = (state = 'loading', action: Action) => {
  switch (action.type) {
    case SELECT_STORE: {
      return (action as StoreInfo).storeNumber;
    }
    default: {
      return state;
    }
  }
};

const localizedStoreDetails = (state: StoreDetail = null, action: Action) => {
  switch (action.type) {
    case SELECT_STORE: {
      return (action as StoreInfo).store || null;
    }
    default: {
      return state;
    }
  }
};

const localizedOccasion = (state: string = null, action: Action) => {
  switch (action.type) {
    case SELECT_STORE: {
      return (action as StoreInfo).occasion || null;
    }
    default:
      return state;
  }
};

const localizationDomain = combineReducers({
  stores,
  localizedStore,
  localizedStoreDetails,
  localizedOccasion
});

export default localizationDomain;
