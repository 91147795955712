import React from 'react';

const SvgComponent = (props: any) => (
  <svg width={24} height={24} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M3.411 6.063L1.031 18.63l5.236 2.901h20.942l4.76-2.9-1.904-12.568z"
      />
      <path
        fill="#131313"
        fillRule="nonzero"
        d="M3.003 6.061l-1.87 12.513 3.739 1.925h22.43l3.738-1.925L29.17 6.06H3.004zM.103 19.17L2.196 5.03h27.779l1.962 13.912-4.413 2.588H4.648L.102 19.17z"
      />
      <path
        fill="#131313"
        fillRule="nonzero"
        d="M1.595 18.534l4.125 1.833c.35.155.57.472.57.82v4.582c0 .68-.807 1.124-1.493.82L.672 24.754c-.349-.155-.57-.472-.57-.82v-4.581c0-.681.807-1.125 1.493-.82zm29.13.001l-3.437 1.833a.924.924 0 00-.476.82v4.582c0 .681.673 1.124 1.244.82l3.438-1.833a.927.927 0 00.475-.82v-4.582c0-.681-.673-1.124-1.244-.82z"
      />
      <path
        fill="#131313"
        fillRule="nonzero"
        d="M5.204 20.499H28c.522 0 .945.395.945.884v4.42c0 .487-.65.528-.945.883H5.204c-.537-.451-.945-.396-.945-.884v-4.42c0-.488.423-.883.945-.883z"
      />
      <path
        fill="#CFCECC"
        fillRule="nonzero"
        d="M1.031 19.469v4.419l4 1.768v-4.419zM31 19.469v4.419l-3 1.768v-4.419zM6.188 21.531v4.125h20.625v-4.125z"
      />
      <path
        fill="#E71316"
        d="M11.64 10l-1.079 2.66L5 15.058v.281h22v-.281l-5.561-2.398L20.36 10z"
      />
    </g>
  </svg>
);

export default SvgComponent;
