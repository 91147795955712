import React from 'react';
import { connect } from 'react-redux';
import CarryoutResults from './CarryoutResults';
import OccasionForm from './OccasionForm';
import { Occasion } from '../constants';
import DeliveryResults from './DeliveryResults';
import { showSearchInRail } from '../actions';


interface LocalizationRailContentViewProps {
  occasion: Occasion;
  searchInRail: boolean;
  showSearchInRail: () => void;
  useSavedAddresses: boolean;
  switchToSavedAddresses: () => void;
  switchToFormFields: () => void;
}

const LocalizationRailContentView = (
  {
    occasion, searchInRail, showSearchInRail,
    useSavedAddresses, switchToSavedAddresses, switchToFormFields
  } : LocalizationRailContentViewProps
) => {
  const onEdit = () => {
    switchToFormFields();
    showSearchInRail();
  };

  const resultsViews = {
    [Occasion.DELIVERY]: <DeliveryResults onEdit={onEdit} />,
    [Occasion.CARRYOUT]: <CarryoutResults onEdit={onEdit} />
  };


  let contentView: React.ReactNode;
  if (searchInRail) {
    contentView = (
      <OccasionForm
        switchToSavedAddresses={switchToSavedAddresses}
        switchToFormFields={switchToFormFields}
        useSavedAddresses={useSavedAddresses}
      />
    );
  } else {
    contentView = resultsViews[occasion];
  }

  return (
    <>
      {contentView}
    </>
  );
};

const mapStateToProps = (state : any) => ({
  searchInRail: state.presentational.localization.searchInRail,
  occasion: state.presentational.localization.occasion
});

const mapDispatchToProps = {
  showSearchInRail
};
export default connect(mapStateToProps, mapDispatchToProps)(LocalizationRailContentView);
