import React from 'react';
import { Hidden } from '@material-ui/core';
import getImplementation from './getResponsiveHidingImplementation';


export default ({ children }: { children: JSX.Element }) => (
  <Hidden mdUp implementation={getImplementation()}>
    {children}
  </Hidden>
);
