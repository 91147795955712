import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const initReduxDevTools = () => ((typeof window !== 'undefined')
// @ts-ignore
  ? (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  : undefined);

const composeEnhancers = initReduxDevTools() || compose;

export default function configureStore(initialState? : {}) : any {
  // configure middlewares
  const middlewares = [thunk];
  // compose enhancers
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  // create store
  const store = createStore(rootReducer, initialState!, enhancer);

  if (process.env.NODE_ENV !== 'production') {
    // @ts-ignore
    if (module.hot) {
      // @ts-ignore
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}
