import React, { ReactElement } from "react";
import ReactDOM from 'react-dom';
import embeddableList from './embeddableList';
import EmbeddedLocalization from "./EmbeddedLocalization";

export class Web1Embedder {

    embeddableList : Array<Embeddable>;

    constructor(embeddableList : Array<Embeddable>) {
        this.embeddableList = embeddableList;
    }

    initializeWeb2() {
        const neighbor = document.querySelector('.ph-content-view');
        const mountPoint = document.createElement('div');
        neighbor!.parentNode!.parentNode!.insertBefore(mountPoint, neighbor!.parentNode!.nextSibling);
        ReactDOM.render(<EmbeddedLocalization />, mountPoint);
    }

    renderForRoute({name}: { name: string; }) {
        const embeddablesForRoute = this
            .embeddableList
            .filter(({onUiState}) => onUiState === name);

        embeddablesForRoute.forEach(embed);
    }
}

export function embed(microApp : { component: () => ReactElement, embedPoint: string }) {
    const element = microApp.component();
    const mountPoint = document.getElementById(microApp.embedPoint);

    ReactDOM.render(element, mountPoint);
}

const web1Embedder = new Web1Embedder(embeddableList);
export default web1Embedder;
