import { Store } from 'redux';
import angularHost from './angularHost';
import Web1UiState from './web1UiState';
import { openModal, openRail, selectStore } from '../../../../phc-web/app/localization/actions';
import { StoreInfo } from "../../../../phc-web/app/localization/localizeActions";
import dataAnalytics from '../../../../phc-web/app/dataAnalytics';
import { modalErrorPopupAnalytics, modalErrorPopupClickAnalytics } from '../../../../phc-web/app/dataAnalytics/dataAnalyticsHelper';
import newRelicAdapter from "../../../../phc-web/app/newRelic/newRelicAdapter";

const dispatchOpenRail = (store: Store, stateName: string, params: any) => {
  const routeTo = processRoute(stateName, params);
  store.dispatch(openRail( { routeTo } ) as any);
  newRelicAdapter.addPageAction('web2-change-from-header-click');
};

const openEmptyCartWarnModal = (store: Store, stateName: string, params: any) => {
  const title = 'Do you want to proceed?';
  const body = 'If you change to a new store the items in your cart will be removed.';

  store.dispatch(openModal({
    title,
    body,
    cta: {
      text: 'PROCEED',
      callback: () => {
        dispatchOpenRail(store, stateName, params);
        dataAnalytics.push(modalErrorPopupClickAnalytics(title, body, 'Proceed'));
      }
    },
    altCta: {
      text: 'CANCEL',
      callback: () => dataAnalytics.push(modalErrorPopupClickAnalytics(title, body, 'Cancel'))
    }
  }));
  dataAnalytics.push(modalErrorPopupAnalytics(title, body));
};

const openCheckoutInfoClearWarnModal = (store: Store, stateName: string, params: any) => {
  const title = 'Please confirm';
  const body = 'If you change stores:';
  const bodyList = [
    'Your cart will be cleared, and',
    'You will have to re-enter your information'
  ];
  const modalBody = `${body} ${bodyList.join(' ')}`;

  store.dispatch(openModal({
    title,
    body,
    bodyList,
    cta: {
      text: 'CONTINUE',
      callback: () => {
        dispatchOpenRail(store, stateName, params);
        dataAnalytics.push(modalErrorPopupClickAnalytics(title, modalBody, 'Continue'));
      }
    },
    altCta: {
      text: 'CANCEL',
      callback: () => dataAnalytics.push(modalErrorPopupClickAnalytics(title, modalBody, 'Cancel'))
    }
  }));
  dataAnalytics.push(modalErrorPopupAnalytics(title, modalBody));
};

const displayWarnModalBasedOnFromState = (store: Store, stateName: string, params: any) => {
  if(stateName === Web1UiState.CHECKOUT_DETAILS || stateName === Web1UiState.PAYMENT) {
    openCheckoutInfoClearWarnModal(store, stateName, params);
  } else {
    openEmptyCartWarnModal(store, stateName, params);
  }
};

const listen = (store: Store) => {
  angularHost.onUiStateChangeStart((
    event: any,
    toState: AngularUiRouterState,
    toParams: any,
    fromState: AngularUiRouterState,
    fromParams: any
  ) => {
    if (toState.name === 'index.w2localize') {
      if(angularHost.orderService().data.cartQuantity) {
        displayWarnModalBasedOnFromState(store, fromState.name, fromParams);
      } else {
        dispatchOpenRail(store, fromState.name, fromParams);
      }
    }
  });

  angularHost.onWeb1LocalizationUpdate((event: any, localizationInfo: StoreInfo) => {
    const storeNumber = localizationInfo?.storeNumber;
    store.dispatch(selectStore(storeNumber));
  });
};

function processRoute(state: string, params: any): string|undefined {
  switch (state) {
    case Web1UiState.MENU:
    case Web1UiState.MENU_PAGE_SUB_SECTIONS:
    case Web1UiState.PIZZA_BUILDER:
    case Web1UiState.ALL_UPSELLS:
    case Web1UiState.ORDER_SUMMARY:
    case Web1UiState.DASHBOARD:
    case Web1UiState.MASTER_DEAL:
    case Web1UiState.DEALS:
    case Web1UiState.CHECKOUT_AUTH:
    case Web1UiState.CHECKOUT_DETAILS:
    case Web1UiState.PAYMENT:
      return 'home';
    case Web1UiState.MENU_PAGES:
      return params?.page !== 'deals' ? 'home' : undefined;
    default:
      break;
  }
}

export default { listen };
