import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import dataAnalytics from '../../dataAnalytics';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import { openRail } from '../actions';
import MapPinIcon from '../icons/MapPinIcon';
import { onRibbonInitialLoad, onRibbonClick } from '../../dataAnalytics/dataAnalyticsHelper';
import newRelicAdapter from '../../newRelic/newRelicAdapter';
import HiddenOnDesktop from '../../common/ResponsiveContext/HiddenOnDesktop';
import HiddenOnMobile from '../../common/ResponsiveContext/HiddenOnMobile';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: 'sharp_sans_semi',
    margin: '24px 0px 4px',
    height: '72px',
    boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      border: '1px solid #e52a33',
      margin: '40px 0px 20px'
    }
  },
  widgetRoot: {
    height: '100%',
    justifyContent: 'space-between'
  },
  mapPinIcon: {
    display: 'flex',
    padding: '0 16px 0 12px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingLeft: '0'
    },
    [theme.breakpoints.down('xl')]: {
      paddingRight: '8px'
    }
  },
  explanationRoot: {
    height: '100%',
    flexWrap: 'nowrap',
    paddingRight: '5px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      border: '0',
      borderRight: '1px solid #cfcecc',
      justifyContent: 'center'
    }
  },
  callToAction: {
    fontWeight: 800,
    fontSize: '28px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '25px'
    },
    fontFamily: 'sharp_sans_extrabold',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '24px',
      lineHeight: '24px'
    }
  },
  mobileActionDescription: {
    fontSize: '14px'
  },
  actionDescription: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '21px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '19px'
    },
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px'
    }
  },
  findDealsContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      height: '100%'
    }
  },
  findDealsButton: {
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#e52a33',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 22px 0 6px',
    color: '#ffffff',
    fontSize: '16px'
  },
  mobileFindDealsButton: {
    height: '100%',
    backgroundColor: '#e52a33',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff'
  }
}));

interface LocalizationRibbonProps {
  openRail: ({ routeTo: string }) => void;
  isLocalizedOrLoadingLocalization: boolean;
}

function LocalizationRibbon({
  openRail, isLocalizedOrLoadingLocalization
} : LocalizationRibbonProps) {
  const classes = useStyles();
  useEffect(() => {
    if (!isLocalizedOrLoadingLocalization) {
      dataAnalytics.pushEventAnalytics(onRibbonInitialLoad());
      newRelicAdapter.addPageAction('web2-localization-ribbon-impression');
    }
  });

  const handleOnRibbonClick = () => {
    dataAnalytics.push(onRibbonClick());
    openRail({ routeTo: 'deals' });
  };

  if (isLocalizedOrLoadingLocalization) {
    return <></>;
  }

  return (
    <Card data-testid="LocalizationRibbon" variant="outlined" className={classes.root} onClick={handleOnRibbonClick}>
      <Grid container spacing={0} direction="row" className={classes.widgetRoot} alignItems="center">
        <Grid item container data-testid="explanation" className={classes.explanationRoot} lg={3} md={4} xs={11} alignItems="center">
          <div className={classes.mapPinIcon}>
            <MapPinIcon />
          </div>
          <div>
            <Typography className={classes.callToAction}>
              START HERE
            </Typography>
            <HiddenOnDesktop>
              <div className={classes.mobileActionDescription}>
                Find your store to see local deals
              </div>
            </HiddenOnDesktop>
          </div>
        </Grid>
        <HiddenOnMobile>
          <Grid container>
            <div className={classes.actionDescription}>
              Find your store to see local deals
            </div>
          </Grid>
        </HiddenOnMobile>
        <Grid item lg={4} md={3} xs={1} data-testid="findDeals" className={classes.findDealsContainer}>
          <HiddenOnMobile>
            <div className={classes.findDealsButton}>
              FIND DEALS
            </div>
          </HiddenOnMobile>
          <HiddenOnDesktop>
            <div data-testid="mobileFindDeals" className={classes.mobileFindDealsButton}>
              <ArrowForwardIosIcon />
            </div>
          </HiddenOnDesktop>
        </Grid>
      </Grid>
    </Card>
  );
}

const mapStateToProps = (state: any) => ({
  isLocalizedOrLoadingLocalization: state.domain.localization.localizedStore !== 'national'
});

const mapDispatchToProps = {
  openRail
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationRibbon);
