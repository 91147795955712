import React from 'react';
import {
  createStyles, Grid, makeStyles, Theme
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  searchCarryout,
  searchDelivery,
  switchToCarryout,
  switchToDelivery,
  searchCarryoutByLatLong
} from '../actions';
import CarryoutIcon from '../icons/CarryoutIcon';
import DeliveryIcon from '../icons/DeliveryIcon';
import OccasionTab from './OccasionTab';
import { Occasion } from '../constants';
import DeliveryFormFields from './DeliveryFormFields';
import CarryoutFormFields from './CarryoutFormFields';
import SavedAddressPicker from './SavedAddressPicker';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import { storeSearchFormAnalytics } from '../../dataAnalytics/dataAnalyticsHelper';
import dataAnalytics from '../../dataAnalytics';

const formUseStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  headerLabel: {
    fontSize: '18px',
    fontFamily: 'sharp_sans_semi',
    margin: '24px 0 24px 5px',
    letterSpacing: '0.4px'
  },
  occasionTabs: {
    paddingBottom: '24px'
  },
  rightFlowSearch: {
    padding: '10px'
  }
}));

export function onSubmitHandler(event: any, search: () => void): void {
  event.preventDefault();
  search();
}

const carryoutDefaultParams: CarryoutSearchDetails = {
  city: undefined,
  state: undefined,
  zipcode: undefined
};

const deliveryDefaultParams: DeliveryAddress = {
  address: '',
  address2: '',
  city: '',
  state: '',
  zipcode: ''
};

interface OccasionFormProps {
  searchDelivery: (options: DeliveryAddress) => void;
  searchCarryout: (options: CarryoutSearchDetails) => void;
  occasion?: Occasion;
  previousSearchDetails: CarryoutSearchDetails | DeliveryAddress;
  savedAddresses: SavedAddress[];
  switchToDelivery: () => void;
  switchToCarryout: () => void;
  isSearchingIndicator: boolean;
  searchCarryoutByLatLong: () => void;
  switchToFormFields: () => void;
  switchToSavedAddresses: () => void;
  useSavedAddresses: boolean;
}

function OccasionForm({
  searchCarryout,
  searchDelivery,
  occasion,
  previousSearchDetails,
  savedAddresses,
  switchToCarryout,
  switchToDelivery,
  isSearchingIndicator,
  searchCarryoutByLatLong,
  switchToFormFields,
  useSavedAddresses,
  switchToSavedAddresses
}:
  OccasionFormProps) {
  const classes = formUseStyles();
  const initialCarryoutState = getInitialCarryoutState();
  const initialDeliveryState = getInitialDeliveryState();
  const includeSavedAddressInAnalytics = useSavedAddresses && savedAddresses.length > 0;

  function getInitialCarryoutState() {
    return previousSearchDetails
      ? { ...carryoutDefaultParams, ...previousSearchDetails }
      : carryoutDefaultParams;
  }

  function getInitialDeliveryState() {
    return previousSearchDetails
      ? { ...deliveryDefaultParams, ...previousSearchDetails }
      : deliveryDefaultParams;
  }
  let formFields;
  if (useSavedAddresses) {
    formFields = (
      <SavedAddressPicker
        savedAddresses={savedAddresses}
        switchToFormFields={switchToFormFields}
        occasion={occasion}
        searchDelivery={searchDelivery}
        searchCarryout={searchCarryout}
        isSearchingIndicator={isSearchingIndicator}
      />
    );
  } else if (occasion === Occasion.DELIVERY) {
    formFields = (
      <DeliveryFormFields
        initialDeliveryState={initialDeliveryState}
        searchDelivery={searchDelivery}
        isSearchingIndicator={isSearchingIndicator}
        switchToSavedAddresses={switchToSavedAddresses}
        showUseSavedAddressButton={savedAddresses.length > 0}
      />
    );
  } else {
    formFields = (
      <CarryoutFormFields
        initialCarryoutState={initialCarryoutState}
        searchCarryout={searchCarryout}
        searchCarryoutByLatLong={searchCarryoutByLatLong}
        isSearchingIndicator={isSearchingIndicator}
        switchToSavedAddresses={switchToSavedAddresses}
        showUseSavedAddressButton={savedAddresses.length > 0}
      />
    );
  }

  const onClickSwitchToCarryout = () => {
    switchToCarryout();
    dataAnalytics.push(storeSearchFormAnalytics('Carryout', false, includeSavedAddressInAnalytics));
  };

  const onClickSwitchToDelivery = () => {
    switchToDelivery();
    dataAnalytics.push(storeSearchFormAnalytics('Delivery', false, includeSavedAddressInAnalytics));
  };

  return (
    <Grid className={classes.content}>
      <Grid container justify="center">
        <Grid container item xs={12}>
          <div className={classes.headerLabel}>Find your store to see local deals</div>
          <Grid className={classes.occasionTabs} container item spacing={1}>
            <Grid item data-testid="carryout-occasion-tab" xs={6}>
              <OccasionTab
                icon={<CarryoutIcon />}
                label="CARRYOUT"
                selected={occasion === Occasion.CARRYOUT}
                onClick={onClickSwitchToCarryout}
              />
            </Grid>
            <Grid item data-testid="delivery-occasion-tab" xs={6}>
              <OccasionTab
                icon={<DeliveryIcon />}
                label="DELIVERY"
                selected={occasion === Occasion.DELIVERY}
                onClick={onClickSwitchToDelivery}
              />
            </Grid>
          </Grid>
          {formFields}
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  previousSearchDetails: state.presentational.localization.previousSearchDetails,
  occasion: state.presentational.localization.occasion,
  isSearchingIndicator: state.presentational.localization.isSearchingIndicator,
  savedAddresses: state.presentational.localization.savedAddresses
});

const mapDispatchToProps = {
  searchCarryout,
  searchDelivery,
  switchToDelivery,
  switchToCarryout,
  searchCarryoutByLatLong
};

export default connect(mapStateToProps, mapDispatchToProps)(OccasionForm);
