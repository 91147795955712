const cartServiceMock: CartService = {
  removeAlcoholFromCart: mockResolvedValue(undefined)
};

function mockResolvedValue<T>(value: T): () => Promise<T> {
  if (typeof (jest) === 'undefined') {
    // When using this mock in our dev server, jest doesn't exist.
    // Handroll a mock instead.
    return () => new Promise((resolve) => resolve(value));
  }

  return jest.fn().mockResolvedValue(value);
}

export default cartServiceMock;
