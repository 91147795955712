/* eslint-disable no-unused-expressions */
export interface NewRelicAdapter {
  addPageAction: (msg: string, attributes?: {}) => void;
  noticeError: (error: Error, customAttributes?: {}) => void;
}

const newRelicInstance : () => NewRelicAdapter = () => (window as any).NREUM;

const newRelicAdapter: NewRelicAdapter = {
  addPageAction: (msg: string, attributes?: {}) => {
    newRelicInstance()?.addPageAction(msg, attributes);
  },
  noticeError: (error: Error, customAttributes?: {}) => {
    newRelicInstance()?.noticeError(error, customAttributes);
  }
};

export default newRelicAdapter;
