import phdapiDataAnalytics from './phdapiDataModel';

export function getDataModel() {
  return process.env.REACT_APP_EMBEDDED === 'true'
    ? lazyLoadAngularBackedService()
    : phdapiDataAnalytics;
}

function lazyLoadAngularBackedService() : any {
  // lazy-requiring this because the angular Data Model object
  // blows up if Nextjs tries to run it server-side
  // (currently depends on the global window object, which is not available server-side)
  /* eslint-disable  global-require */
  return require('./angularDataModel').default;
}

export default getDataModel();
