/* eslint-disable import/no-mutable-exports */
/* eslint-disable global-require */
/* eslint-disable no-console */

let logger = {
  info: (msg: string) => console.log(msg),
  error: (msg: string) => console.error(msg),
  warn: (msg: string) => console.warn(msg),
  debug: (msg: string) => console.debug(msg),
  log: (level: string, msg: string) => console.log(msg)
};

// Override logger with Winston lib on NextJS server side
// Prevents big logging library from being included in client bundle
if (process.env.REACT_APP_EMBEDDED === 'true') {
  // Embedded build seems to only tree-shake properly with this empty code block
  // Juggling two build processes is hard, ok?
} else if (typeof window === 'undefined') {
  const getWinstonConfigLogger = require('./winstonConfig').default;
  logger = getWinstonConfigLogger();
}

export default logger;

/* eslint-enable import/no-mutable-exports */
/* eslint-disable global-require */
/* eslint-enable no-console */
