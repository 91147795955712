import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Drawer, makeStyles, Theme, createStyles
} from '@material-ui/core';
import RailHeader from './RailHeader';
import LocalizationRailContentView from './LocalizationRailContentView';
import { closeRail } from '../actions';
import CarryoutIcon from '../icons/CarryoutIcon';
import DeliveryIcon from '../icons/DeliveryIcon';
import { Occasion, Z_INDEX_LOWER_THAN_GOOGLE_AUTO_COMPLETE } from '../constants';

interface LocalizationRailPropTypes {
  visible: boolean;
  occasion: Occasion;
  closeRail: () => void;
  searchInRail: boolean;
  savedAddresses: SavedAddress[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    width: '35%',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  }
}));

function LocalizationRail({
  visible, occasion, closeRail, searchInRail, savedAddresses
}: LocalizationRailPropTypes) {
  const classes = useStyles();
  const [useSavedAddresses, setSavedAddress] = useState(savedAddresses.length > 0);
  const includeSavedAddressInAnalytics = useSavedAddresses && savedAddresses.length > 0;

  const switchToSavedAddresses = () => setSavedAddress(true);
  const switchToFormFields = () => setSavedAddress(false);

  useEffect(() => {
    setSavedAddress(savedAddresses.length > 0);
  }, [savedAddresses]);

  const headerProps = {
    [Occasion.DELIVERY]: {
      label: searchInRail ? 'Start here' : 'Delivery',
      testId: 'delivery',
      icon: <DeliveryIcon />,
      includeSavedAddressInAnalytics
    },
    [Occasion.CARRYOUT]: {
      label: searchInRail ? 'Start here' : 'Carryout',
      testId: 'carryout',
      icon: <CarryoutIcon />,
      includeSavedAddressInAnalytics
    }
  };

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      style={{ zIndex: Z_INDEX_LOWER_THAN_GOOGLE_AUTO_COMPLETE }}
      anchor="right"
      onClose={closeRail}
      open={visible}
      data-testid="localization-rail"
    >
      <RailHeader onClickClose={closeRail} {...headerProps[occasion]} />
      <LocalizationRailContentView
        useSavedAddresses={useSavedAddresses}
        switchToSavedAddresses={switchToSavedAddresses}
        switchToFormFields={switchToFormFields}
      />
    </Drawer>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.presentational.localization.rail.options.isOpen,
  occasion: state.presentational.localization.occasion,
  searchInRail: state.presentational.localization.searchInRail,
  savedAddresses: state.presentational.localization.savedAddresses
});

const mapDispatchToProps = {
  closeRail
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationRail);
