import {
  makeStyles, createStyles, TextField, InputProps, Theme
} from '@material-ui/core';
import React from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&$focused $notchedOutline, &:hover $notchedOutline': {
      borderColor: '#131313',
      borderWidth: '1px',
      boxShadow: '0 2px 4px 0 rgba(19, 19, 19, 0.5)'
    }
  },
  input: {
    padding: '10px',
    '&::placeholder': {
      fontStyle: 'italic',
      color: '#717170',
      opacity: '1'
    }
  },
  focused: { },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#717170'
  },
  error: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
      borderColor: '#e52a33'
    },
    '&$focused $notchedOutline, &:hover $notchedOutline': {
      borderColor: '#e52a33'
    }
  }
}));

interface OutlineTextInputProps {
  id: string,
  testId: string,
  value?: string,
  placeholder?: string,
  onChange: (input: string) => void,
  icon?: InputProps,
  defaultValue?: string,
  name?: string,
  register?: any,
  error?: boolean,
  onKeyPress?: (event: any) => void
}

const OutlineTextInput = ({
  id, placeholder, onChange, testId, icon, defaultValue, name, value, register, error, onKeyPress
}: OutlineTextInputProps) => {
  const classes = useStyles();
  const props = {
    id, placeholder, name, value
  };

  return (
    <TextField
      error={error}
      InputProps={{ classes, ...icon }}
      inputProps={{ 'data-testid': testId, defaultValue }}
      variant="outlined"
      inputRef={register || null}
      onChange={({ target }) => onChange(target.value)}
      {...props}
      fullWidth
      onKeyPress={onKeyPress}
    />
  );
};

export default OutlineTextInput;
