import React from 'react';
import Web1UiState from './angular-adapters/web1UiState';
import LocalizationRibbon from "../../../phc-web/app/localization/localizationRibbon/LocalizationRibbon";
import Embed from "./embed";

const ribbon : Embeddable = {
    component: () => (
        <Embed>
            <LocalizationRibbon />
        </Embed>
    ),
    embedPoint: 'web2Widget',
    onUiState: Web1UiState.HOME
}

const embeddableList : Array<Embeddable> = [
    ribbon
];

export default embeddableList;
