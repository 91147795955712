import React from 'react';

const SvgComponent = (props: any) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="a"
        d="M16 1.611L14.389 0 8 6.389 1.611 0 0 1.611 6.389 8 0 14.389 1.611 16 8 9.611 14.389 16 16 14.389 9.611 8z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#717170" fillRule="nonzero" xlinkHref="#a" />
      <g fill="#717170" mask="url(#b)">
        <path d="M-8-8h32v32H-8z" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
