import AngularRootScopeEvent from "../angularRootScopeEvent";

function currentUiState() : AngularUiRouterState | undefined {
    const angularRoot = rootScope();
    return (angularRoot) ? angularRoot.$state.$current : undefined;
}

function goToUiState(route : string, options? : { page: string }) : void {
    rootScope().$state.go(route, options);
}

function onUiStateChangeStart(handler: AngularStateChangeHandler) {
    rootScope().$on(AngularRootScopeEvent.StateChangeStart, handler);
}

function onUiStateChangeSuccess(handler: AngularStateChangeHandler) {
    rootScope().$on(AngularRootScopeEvent.StateChangeSuccess, handler);
}

function onWeb1LocalizationUpdate(handler: AngularStateChangeHandler) {
    rootScope().$on(AngularRootScopeEvent.Web1LocalizationUpdate, handler);
}

function getWeb1InitLocalizedStore() {
    return rootScope().web1InitLocalizedStore;
}

interface Global extends NodeJS.Global {
   angular: AngularApp
}

function angularApp() : AngularElement {
    return (global as Global).angular.element(document.querySelector('.ph-content-view'));
}

function rootScope() : AngularRootScope {
    return angularApp().scope().$root;
}

function localizationService() : AngularLocalizationService {
    return angularApp().injector().get('LocalizationService');
}

function orderService() : AngularOrderService {
    return angularApp().injector().get('OrderService');
}

function accountService() : AngularAccountService {
    return angularApp().injector().get('AccountService');
}

function menuService() : AngularMenuService {
    return angularApp().injector().get('MenuService');
}

export default {
    currentUiState,
    goToUiState,
    onUiStateChangeStart,
    onUiStateChangeSuccess,
    onWeb1LocalizationUpdate,
    getWeb1InitLocalizedStore,
    localizationService,
    orderService,
    accountService,
    menuService
}
