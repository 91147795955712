import colors from './colors';

const sharedFontStyle = {
  fontFamily: 'sharp_sans_semi',
  fontSize: '14px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal'
};

const fontStyles = {
  h5: {
    ...sharedFontStyle,
    fontWeight: 600,
    fontSize: '12px',
    color: colors.gray900
  },
  headerMainNavLink: {
    ...sharedFontStyle,
    color: colors.gray900,
    fontSize: '18px',
    lineHeight: '24px',
    textDecoration: 'none'
  },
  headerPersonalizationLink: {
    ...sharedFontStyle,
    fontSize: '14px',
    color: colors.gray900,
    textDecoration: 'none'
  },
  bodySmall: {
    ...sharedFontStyle,
    fontFamily: 'open_sans',
    fontSize: '12px',
    lineHeight: 1.5,
    color: colors.gray900
  },
  redTextLink: {
    ...sharedFontStyle,
    fontWeight: 600,
    color: colors.red,
    'text-transform': 'uppercase'
  }
};

export default fontStyles;
