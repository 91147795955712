import React from 'react';
import {
  Card, createStyles, makeStyles, Theme, Grid, Hidden, Box
} from '@material-ui/core';
import ClockIcon from '../../icons/ClockIcon';
import PhoneIcon from '../../icons/PhoneIcon';
import ExpandableDetails from '../ExpandableDetails';
import StoreTileHeader from './StoreTileHeader';
import PizzaOvenIcon from '../../icons/PizzaOvenIcon';
import StoreTileCta from './StoreTileCta';
import { Occasion } from '../../constants';
import { StoreTileHeaderType } from './constants';
import determineStoreTileStatus from './determineStoreTileStatus';

const useStyles = makeStyles((theme: Theme) => {
  const arrowSize = 14;
  const root = {
    padding: '0 15px 10px 15px',
    marginRight: '5px'
  };
  return createStyles({
    rootWithOutCursor: {
      ...root
    },
    rootSelected: {
      padding: '0 15px 10px 15px',
      border: '2px solid #e52a33',
      marginRight: '5px',
      cursor: 'pointer',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
    },
    rootWithCursor: {
      ...root,
      cursor: 'pointer'
    },
    promiseTime: {
      fontWeight: 600,
      fontFamily: 'open_sans_semi'
    },
    width100: {
      width: '100%'
    },
    MuiGrid: {
      root: {
        borderColor: 'red'
      }
    },
    landmark: {
      color: '#717170',
      fontSize: '14px',
      fontFamily: 'open_sans_semi',
      fontStyle: 'italic'
    },
    phoneNumber: {
      marginTop: '8px'
    },
    storeHours: {
      marginTop: '6px'
    },
    secondShift: {
      marginLeft: '30px'
    },
    actionArea: {
      '&:hover $focusHighlight': {
        opacity: 0
      }
    },
    focusHighlight: {},
    arrowDown: {
      display: 'block',
      width: arrowSize,
      height: arrowSize,
      border: '2px solid transparent',
      borderBottomColor: theme.palette.primary.main,
      borderLeftColor: theme.palette.primary.main,
      marginTop: '-94px',
      transform: 'rotate(-135deg)',
      backgroundColor: 'white',
      float: 'right'
    }
  });
});

const strippedPhoneNumber = (rawPhoneNumber: string) => rawPhoneNumber.replace(/[^\d]/g, '');

const convertedPhoneNumber = (rawPhoneNumber: string) => strippedPhoneNumber(rawPhoneNumber)
  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

interface StoreTileProps {
  index: number;
  store: StoreDetail;
  onContinue: (storeNumber: string, removeAlcohol: boolean) => void;
  selectStoreTile?: (panel: string, isExpanded: string, storeNumber: string) => void;
  expandState?: string;
  expandable?: boolean;
  onSwitchOccasion: () => void;
  occasion: Occasion.DELIVERY | Occasion.CARRYOUT;
}

function StoreTile({
  index,
  store,
  onContinue,
  expandState,
  selectStoreTile,
  expandable = false,
  onSwitchOccasion,
  occasion
}: StoreTileProps) {
  const classes = useStyles();

  const toggleStoreTileExpansion = (storeTileIndex: string, storeNumber: string) => {
    const toggleExpandState = (expandState === storeTileIndex) ? '' : storeTileIndex;
    selectStoreTile(storeTileIndex, toggleExpandState, storeNumber);
  };

  const isExpanded = expandState && (expandState === `store-tile-${index}`);
  const expandableRootClass = isExpanded ? classes.rootSelected : classes.rootWithCursor;

  const {
    buttonType,
    headerType,
    storeStatusMessage
  } = determineStoreTileStatus({
    occasion,
    store
  });

  const storeHoursExist = store.openTime || store.closeTime;
  const displaySecondShift = occasion === Occasion.DELIVERY && store.splitOpenTime;

  return (
    <div
      data-testid={`store-tile-${index}`}
    >
      <Card
        onClick={() => selectStoreTile && toggleStoreTileExpansion(`store-tile-${index}`, store.storeNumber)}
        className={expandable
          ? expandableRootClass
          : classes.rootWithOutCursor}
        elevation={4}
      >
        <Grid container direction="column" spacing={1}>
          <Grid
            item
            className={`${classes.promiseTime} ${headerType === StoreTileHeaderType.OCCASION_NOT_AVAILABLE ? classes.width100 : ''}`}
          >
            <PizzaOvenIcon />
            <Box component="div" display="inline" data-testid={`store-header-${index}`}>
              <StoreTileHeader
                store={store}
                occasion={occasion}
                headerType={headerType}
                index={index}
              />
            </Box>
          </Grid>
          <ExpandableDetails
            expanded={expandable
              ? !!isExpanded
              : true}
            summary={(
              <Grid item data-testid={`store-tile-address-${index}`}>
                {store.address}
              </Grid>
            )}
          >
            <Grid item data-testid={`store-tile-city-state-zip-${index}`}>
              {store.city}{store.state && <span>, {store.state}</span>} {store.zipcode}
            </Grid>
            {
              store.landmark && (
              <Grid item data-testid={`store-tile-landmark-${index}`} className={classes.landmark}>
                {store.landmark}
              </Grid>
              )
            }
            {
              store.phoneNumber && (
                <Grid item data-testid={`store-tile-phone-${index}`} className={classes.phoneNumber}>
                  <PhoneIcon />
                  <Hidden mdDown>
                    {convertedPhoneNumber(store.phoneNumber)}
                  </Hidden>
                  <Hidden lgUp>
                    <a href={`tel:${strippedPhoneNumber(store.phoneNumber)}`}>
                      {convertedPhoneNumber(store.phoneNumber)}
                    </a>
                  </Hidden>
                </Grid>
              )
            }
          </ExpandableDetails>
          <Grid container className={classes.storeHours}>
            <Grid
              container
              direction={storeStatusMessage ? 'column' : 'row'}
            >
              {storeHoursExist
                && (
                  <Grid item data-testid={`store-tile-hours-${index}`}>
                    <ClockIcon />{store.openTime} - {store.closeTime}
                  </Grid>
                )}
              {displaySecondShift
                ? (
                  <Grid
                    container
                    direction="column"
                  >
                    <Grid item className={classes.secondShift}>
                      {store.splitOpenTime} - {store.splitCloseTime}
                    </Grid>
                    <StoreTileCta
                      store={store}
                      index={index}
                      onContinue={onContinue}
                      onSwitchOccasion={onSwitchOccasion}
                      buttonType={buttonType}
                      occasion={occasion}
                      storeStatusMessage={storeStatusMessage}
                    />
                  </Grid>
                )
                : (
                  <StoreTileCta
                    store={store}
                    index={index}
                    onContinue={onContinue}
                    onSwitchOccasion={onSwitchOccasion}
                    buttonType={buttonType}
                    occasion={occasion}
                    storeStatusMessage={storeStatusMessage}
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {
        expandable
        && isExpanded
        && (
          <span
            data-testid="arrow-pointing-box"
            className={classes.arrowDown}
          />
        )
      }
    </div>
  );
}

export default StoreTile;
