import angularHost from './angularHost';
import { Store } from 'redux';
import { selectStore } from '../../../../phc-web/app/localization/actions';
import angularConnectionConfig from "./angularConnectionConfig";
import newRelicAdapter from "../../../../phc-web/app/newRelic/newRelicAdapter";

const logToNewRelicIfLoadIsLong = (count: number) => {
  if(count >= angularConnectionConfig._30secRetry){
    const millis = count * angularConnectionConfig.retryIntervalMs;
    newRelicAdapter.addPageAction('web2-long-wait-for-angular-complete', { millis });
  }
};

function checkIfAngularIsLoaded(): Promise<AngularUiRouterState> {
  let count = 1;

  return new Promise((resolve, reject) => {
    const timer = setInterval(() => {
      let uiState;
      try {
        uiState = angularHost.currentUiState();
      } catch { }

      if (uiState) {
        clearInterval(timer);
        logToNewRelicIfLoadIsLong(count);
        resolve(uiState);
      } else if(count % angularConnectionConfig._30secRetry === 0) { // should be a multiple of 300 to log for every 30 secs
        newRelicAdapter.addPageAction('web2-embedded-app-waited-more-than-30-seconds');
      }
      count++;
    }, angularConnectionConfig.retryIntervalMs);
  });
}

function checkLocalizedStore(store: Store): void {
  const timer = setInterval(() => {
    const storeNumber = angularHost.getWeb1InitLocalizedStore();
    if (storeNumber && storeNumber !== 'loading') {
      store.dispatch(selectStore(storeNumber));
      clearInterval(timer);
    } else if (!storeNumber) {
      store.dispatch(selectStore('national'));
      clearInterval(timer);
    }
  }, 100);
}

export default {
  checkLocalizedStore,
  checkIfAngularIsLoaded
}
