import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import expansionPanelStyles from './styles';

interface ExpandableDetailsProps {
  expanded: boolean,
  summary: any,
  children: any
}

export default function ExpandableDetails(props : ExpandableDetailsProps) {
  const { expanded, summary, children } = props;
  const expandPanelClasses = expansionPanelStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      classes={{
        root: expandPanelClasses.expansionPanelRoot,
        rounded: expandPanelClasses.expansionPanelRounded
      }}
    >
      <ExpansionPanelSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        classes={{
          root: expandPanelClasses.root,
          content: expandPanelClasses.content,
          expandIcon: expandPanelClasses.expandIconStyle
        }}
        expandIcon={
            !expanded && (
            <ExpandMoreIcon
              classes={{ root: expandPanelClasses.expandIconSvgStyle }}
              data-testid="expand-more-icon"
            />
            )
        }
      >
        {summary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{
        root: expandPanelClasses.detailRoot
      }}
      >
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
