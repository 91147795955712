const colors = {
  blueLinkHover: '#113E7B',
  gray900: '#131313',
  gray600: '#717171',
  gray400: '#CFCECC',
  blue: '#266DCC',
  red: '#E71316'
};

export default colors;
