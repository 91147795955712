import connectionConfig from '../../../../../phc-web/app/localization/services/connectionConfig';

const promiseWithTimeout = (promise: Promise<any>, timeout: number) => {
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => {
      reject(new Error(connectionConfig.requestTimeoutErrorMessage));
    }, timeout)
  );

  return Promise.race([promise, timeoutPromise]);
};

export default promiseWithTimeout;