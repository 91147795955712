import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Box, createStyles, Grid, List, ListItem, makeStyles, Theme
} from '@material-ui/core';
import { connect } from 'react-redux';
import { closeModal } from '../localization/actions';

const styles = makeStyles((theme: Theme) => createStyles({
  title: {
    color: '#131313',
    fontSize: '28px',
    fontFamily: 'sharp_sans_bold',
    marginTop: '4px'
  },
  body: {
    color: '#131313',
    fontSize: '14px',
    fontFamily: 'open_sans',
    marginBottom: 0
  },
  bodyListItem: {
    padding: '0 10px 0',
    display: 'list-item',
    listStyle: 'disc inside none'
  },
  ctaBtnContainer: {
    justifyContent: 'center',
    marginBottom: '17px',
    padding: '10px 24px'
  },
  altCtaBtn: {
    backgroundColor: '#ffffff',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  }
}));

interface ModalProps {
  open: boolean;
  content: ModalContent;
  closeModal: () => void;
}

function Modal(props: ModalProps) {
  const { open, content, closeModal } = props;

  const classes = styles();
  const {
    title, body, bodyList, cta, altCta
  } = content;

  const ctaExists = cta && !!cta.text;
  const altCtaExists = altCta && !!altCta.text;

  const displayList = (list: string[]) => (
    <List>
      {
        list.map(
          (listItem, index) => (
            <ListItem key={index} className={classes.bodyListItem}>
              { listItem }
            </ListItem>
          )
        )
      }
    </List>
  );

  const handleCtaClick = () => {
    closeModal();
    if (cta.callback) cta.callback();
  };

  const handleAltCtaClick = () => {
    closeModal();
    if (altCta.callback) altCta.callback();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle data-testid="alert-dialog-title" className={classes.title} disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText data-testid="alert-dialog-description" className={classes.body}>
            {body}
          </DialogContentText>
          { bodyList?.length && displayList(bodyList) }
        </DialogContent>
        <DialogActions classes={{ root: classes.ctaBtnContainer }}>
          <Grid container spacing={2}>
            { ctaExists && (
            <Box clone order={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={altCtaExists ? 6 : 12}>
                <Button
                  onClick={handleCtaClick}
                  color="primary"
                  variant="contained"
                  data-testid="modal-cta-btn"
                  fullWidth
                  disableRipple
                >
                  {cta.text}
                </Button>
              </Grid>
            </Box>
            )}
            { altCtaExists && (
            <Box clone order={{ xs: 2, md: 1 }}>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={handleAltCtaClick}
                  classes={{ root: classes.altCtaBtn }}
                  variant="contained"
                  data-testid="modal-alt-cta-btn"
                  fullWidth
                  disableRipple
                >
                  {altCta.text}
                </Button>
              </Grid>
            </Box>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  open: state.presentational.localization.modal.open,
  content: state.presentational.localization.modal.data
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
