import React from 'react';
import {
  Grid, Card, makeStyles, createStyles, Theme, Button
} from '@material-ui/core';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import LinkButton from '../../common/LinkButton';
import CaratIcon from '../icons/CaratIcon';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    padding: '24px 24px 0 24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '16px 16px 0 16px'
    }
  },
  subheader: {
    fontFamily: 'sharp_sans_semi',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1.33,
    marginBottom: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px',
      marginBottom: '16px'
    }
  },
  addressTile: {
    padding: '12px 15px 15px 15px',
    marginBottom: '16px'
  },
  tileContent: {
    alignItems: 'center'
  },
  line: {
    color: '#cfcecc',
    marginLeft: '8px',
    marginRight: '8px'
  },
  flexGrow: {
    flexGrow: 1
  },
  ctaButton: {
    fontFamily: 'sharp_sans_semi',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: '1px',
    padding: '0px',
    float: 'right',
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

interface DeliveryAddressesProps {
  suggestedAddresses: DeliveryAddress[];
  onEditClick: () => void;
  onSwitchClick: () => void;
  searchDelivery: (options: DeliveryAddress, skipGeoCode: boolean) => void;
  userAddress: DeliveryAddress;
}

interface SuggestedAddressProps {
  address: DeliveryAddress;
  index: number;
  skipGeoCode?: boolean;
}

function DeliveryAddresses({
  suggestedAddresses, onEditClick, onSwitchClick, searchDelivery, userAddress
}: DeliveryAddressesProps) {
  const classes = useStyles();

  const onSearch = (address: DeliveryAddress, skipGeoCode?: boolean) => {
    searchDelivery(address, skipGeoCode);
  };

  const SuggestedAddress = ({ address, index, skipGeoCode }: SuggestedAddressProps) => (
    <Card
      data-testid={`delivery-address-tile-${index}`}
      key={index}
      className={classes.addressTile}
      elevation={4}
    >
      <Grid container className={classes.tileContent}>
        <Grid item>
          {address.address} {address.address2}
          <br />
          {address.city}{address.state && (
            <span>
              , {address.state}
            </span>
          )} {address.zipcode}
        </Grid>
        <Grid item className={classes.flexGrow}>
          <Button
            disableRipple
            data-testid={`delivery-address-tile-btn-${index}`}
            aria-label={`delivery address tile btn ${index}`}
            className={classes.ctaButton}
            color="primary"
            onClick={() => onSearch(address, skipGeoCode)}
            endIcon={<CaratIcon />}
          >
            Select
          </Button>
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <Grid
      container
      direction="column"
      spacing={0}
      className={classes.content}
    >
      <Grid className={classes.subheader}>
        We couldn&apos;t find your exact location
      </Grid>
      <Grid className={classes.subheader}>
        Did you mean:
      </Grid>
      <SuggestedAddress address={userAddress} index={0} skipGeoCode />
      {
        suggestedAddresses.map((address, index) => (
          <SuggestedAddress key={index + 1} address={address} index={index + 1} />
        ))
      }
      <Grid item>
        <LinkButton testId="edit-location" onClick={onEditClick}>Edit location</LinkButton>
        <span className={classes.line}>|</span>
        <LinkButton testId="change-occasion" onClick={onSwitchClick}>Change to carryout</LinkButton>
      </Grid>

    </Grid>
  );
}

export default DeliveryAddresses;
