export enum Occasion {
    CARRYOUT = 'C',
    DELIVERY = 'D'
}

export enum OccasionString {
    C = 'Carryout',
    D = 'Delivery'
}

export const Z_INDEX_LOWER_THAN_GOOGLE_AUTO_COMPLETE = 1049;
