import { Occasion } from '../constants';

const makeMultipleOfFive = (promiseTime: number) => ((promiseTime % 5 !== 0)
  ? promiseTime - (promiseTime % 5) + 5
  : promiseTime);

const transformPromiseTime = (occasion: string, promiseTime: number, stale?: boolean) => {
  if (occasion === Occasion.CARRYOUT) return `${promiseTime} mins`;

  const actualPromiseTime = makeMultipleOfFive(promiseTime);

  if (!stale && promiseTime > 0) {
    return `${actualPromiseTime - 5} - ${actualPromiseTime + 5} min`;
  }

  return '30 - 40 min';
};

export default transformPromiseTime;
