import angularHost from '../../../../../embedded-web2/src/embedding-framework/angular-adapters/angularHost';
import { Occasion } from '../../constants';
import { StoreStatus } from '../../localizationRail/StoreTile/constants';
import promiseWithTimeout
  from '../../../../../embedded-web2/src/embedding-framework/angular-adapters/promiseWithTimeout';
import connectionConfig from '../connectionConfig';

export default {
  /**
     * Service call to get the CARRYOUT STORE SEARCH results
     * findNearByStoresByAddress() is the Angular method
     * in the LOCALIZATION SERVICE
     */
  findCarryoutStores(carryoutSearchDetails: CarryoutSearchDetails): Promise<Array<StoreDetail>> {
    const angularService = angularHost.localizationService();
    return promiseWithTimeout(angularService
      .findNearByStoresByAddress(Occasion.CARRYOUT,
        undefined,
        undefined,
        carryoutSearchDetails.zipcode,
        undefined,
        undefined,
        carryoutSearchDetails.city,
        carryoutSearchDetails.state,
        undefined,
        carryoutSearchDetails.lat,
        carryoutSearchDetails.lng)
      .then(({ stores }) => stores)
      .then((stores) => stores.map(transformCarryout)), connectionConfig.requestTimeoutMs);
  },
  findCarryoutStoresByLatLong(
    carryoutSearchDetails: CarryoutSearchDetails
  ): Promise<Array<StoreDetail>> {
    const angularService = angularHost.localizationService();
    return promiseWithTimeout(angularService
      .findNearByStoresByLatLng(Occasion.CARRYOUT,
        undefined,
        undefined,
        carryoutSearchDetails.lat,
        carryoutSearchDetails.lng)
      .then(({ stores }) => stores)
      .then((stores) => stores.map(transformCarryout)), connectionConfig.requestTimeoutMs);
  },
  findDeliveryStoresByLatLong(
    deliveryAddress: DeliveryAddress,
    skipGeocode: boolean = false
  ): Promise<DeliverySearchResult> {
    const angularService = angularHost.localizationService();
    return promiseWithTimeout(angularService
      .findNearByStoresByLatLng(Occasion.DELIVERY,
        undefined,
        undefined,
        deliveryAddress.lat,
        deliveryAddress.lng,
        deliveryAddress.zipcode,
        deliveryAddress.address,
        deliveryAddress.address2,
        deliveryAddress.city,
        deliveryAddress.state,
        undefined,
        undefined,
        undefined,
        skipGeocode ? 'Y' : undefined)
      .then(transformDelivery), connectionConfig.requestTimeoutMs);
  },
  findDeliveryStores(deliveryAddress: DeliveryAddress): Promise<DeliverySearchResult> {
    const angularService = angularHost.localizationService();
    return promiseWithTimeout(angularService
      .findNearByStoresByAddress(
        Occasion.DELIVERY,
        undefined,
        undefined,
        deliveryAddress.zipcode,
        deliveryAddress.address,
        deliveryAddress.address2,
        deliveryAddress.city,
        deliveryAddress.state,
        undefined,
        undefined,
        undefined
      )
      .then(transformDelivery), connectionConfig.requestTimeoutMs);
  },

  selectCarryoutStore(
    { storeNumber }: { storeNumber: string }
  ): Promise<void> {
    // Angular Localization Service expects an object with the key StoreNumber
    const angularStore = { StoreNumber: storeNumber };
    const angularLocalizationService = angularHost.localizationService();
    const angularOrderService = angularHost.orderService();
    const cartQty = angularOrderService.data.cartQuantity;
    return angularLocalizationService
      .confirmLocation('C', angularStore, null, null, cartQty);
  },

  selectDeliveryStore(
    storeNumber: string, deliveryAddress: DeliveryAddress
  ): Promise<void> {
    const angularStore = { StoreNumber: storeNumber };
    const angularLocalizationService = angularHost.localizationService();
    const angularOrderService = angularHost.orderService();
    const cartQty = angularOrderService.data.cartQuantity;
    const deliveryInfo = {
      address: deliveryAddress.address,
      address_two: deliveryAddress.address2,
      city: deliveryAddress.city,
      state: deliveryAddress.state.toUpperCase(),
      zip: deliveryAddress.zipcode,
      storeNumber,
      zone: undefined,
      dwellCode: undefined,
      locationName: undefined,
      lat: undefined,
      lng: undefined
    };
    return angularLocalizationService
      .confirmLocation('D', angularStore, deliveryInfo, null, cartQty);
  }
};

function checkIfStoreStatusInactive(rawStatus: string, onlineStatus: string): string {
  /*
   * Angular API returns the incorrect `storeStatus` when the store is inactive
   * (it returns as 'T' instead of 'I')
   * that's why, we are setting `storeStatus` from 'T' to 'I' explicitly if the store is inactive
   * in the transform layer.
   */
  return rawStatus === StoreStatus.TEMP_CLOSED && onlineStatus === 'inactive'
    ? StoreStatus.INACTIVE
    : rawStatus;
}

function transformCarryout(angularStoreStructure: any): StoreDetail {
  const status = checkIfStoreStatusInactive(
    angularStoreStructure.info.status.raw,
    angularStoreStructure.info.onlineStatus
  );

  return {
    storeNumber: angularStoreStructure.StoreNumber,
    address: angularStoreStructure.info.address,
    city: angularStoreStructure.info.city,
    state: angularStoreStructure.info.state,
    zipcode: angularStoreStructure.info.zip.toString(),
    landmark: angularStoreStructure.info.landmark?.toString(),
    phoneNumber: angularStoreStructure.info.phone?.toString(),
    promiseTime: angularStoreStructure.info.promiseTimeCo,
    openTime: angularStoreStructure.info.carryout_open,
    closeTime: angularStoreStructure.info.carryout_close,
    storeStatus: status,
    acceptFutureOrders: angularStoreStructure.info.acceptFutureOrders,
    storeClosureReason: angularStoreStructure.info.tmp_offline_msg,
    lat: parseFloat(angularStoreStructure.lat),
    long: parseFloat(angularStoreStructure.long)
  };
}

function transformDelivery(response: any): DeliverySearchResult {
  if (response?.multiple) {
    return {
      deliveryAddresses: response.multiple.map((angularAddressStructure) => ({
        address: angularAddressStructure.address,
        address2: angularAddressStructure.address2,
        city: angularAddressStructure.city,
        state: angularAddressStructure.state,
        zipcode: angularAddressStructure.zip,
        country: angularAddressStructure.country,
        lat: parseFloat(angularAddressStructure.latitude),
        lng: parseFloat(angularAddressStructure.longitude)
      }))
    };
  }

  const stores = response.stores.map((angularStoreStructure) => {
    const status = checkIfStoreStatusInactive(
      angularStoreStructure.info.status.raw,
      angularStoreStructure.info.onlineStatus
    );

    const includeBeerWarning = status.includes(Occasion.DELIVERY)
      && !response.address.boozeId
      && response.localizingToSameStore
      && angularHost.orderService().getAlcoholItemInCart();

    return {
      storeNumber: angularStoreStructure.StoreNumber,
      address: angularStoreStructure.info.address,
      city: angularStoreStructure.info.city,
      state: angularStoreStructure.info.state,
      zipcode: angularStoreStructure.info.zip.toString(),
      phoneNumber: angularStoreStructure.info.phone?.toString(),
      promiseTime: angularStoreStructure.info.promiseTimeDel,
      promiseTimeStale: angularStoreStructure.info.promiseTimeDelStale,
      openTime: angularStoreStructure.info.delivery_open,
      closeTime: angularStoreStructure.info.delivery_close,
      splitOpenTime: angularStoreStructure.info.split_delivery_open || undefined,
      splitCloseTime: angularStoreStructure.info.split_delivery_close || undefined,
      storeStatus: status,
      acceptFutureOrders: angularStoreStructure.info.acceptFutureOrders,
      storeClosureReason: angularStoreStructure.info.tmp_offline_msg,
      includeBeerWarning
    };
  });

  return {
    stores
  };
}
