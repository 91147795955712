export const CARRYOUT_SEARCH_SUCCESS = 'CARRYOUT_SEARCH_SUCCESS';
export const DELIVERY_SEARCH_SUCCESS = 'DELIVERY_SEARCH_SUCCESS';
export const OPEN_LOCALIZATION_RAIL = 'OPEN_LOCALIZATION_RAIL';
export const CLOSE_LOCALIZATION_RAIL = 'CLOSE_LOCALIZATION_RAIL';
export const SHOW_SEARCH_IN_RAIL = 'SHOW_SEARCH_IN_RAIL';
export const SWITCH_TO_DELIVERY = 'SWITCH_TO_DELIVERY';
export const SWITCH_TO_CARRYOUT = 'SWITCH_TO_CARRYOUT';
export const SHOW_LOADING_INDICATOR = 'SHOW_LOADING_INDICATOR';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const CLOSE_MODAL = 'CLOSE_ERROR_MODAL';
export const OPEN_MODAL = 'OPEN_ERROR_MODAL';
export const LOAD_SAVED_ADDRESSES = 'LOAD_SAVED_ADDRESSES';
export const SELECT_STORE = 'SELECT_STORE';
