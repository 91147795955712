// @ts-ignore
__webpack_public_path__ = window.PHWEB2.embeddedBaseUrl;

// Why using "require" and not "import"? 
// See: https://github.com/webpack/webpack/issues/2776
const embeddedApp = require('./embeddedApp').default

try {
    embeddedApp.start();
} catch(e) {
    console.log('web2 error:', e);
    throw e;
}