import { Button, makeStyles, createStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => createStyles({
  root: {
    '&.MuiButton-root': {
      textTransform: 'none',
      color: '#266dcc',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'open_sans_semi'
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },
    '&.MuiButton-text': {
      padding: '0px'
    }
  }
}));

interface LinkButtonProps {
    testId: string;
    onClick: () => void;
    children?: React.ReactNode;
    startIcon?: React.ReactNode;
}

const LinkButton = (props: LinkButtonProps) => {
  const { testId, children, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      data-testid={testId}
      classes={classes}
      disableRipple
      variant="text"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
