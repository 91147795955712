import { useContext } from 'react';
import CreateResponsiveContext from './createResponsiveContext';

enum Implementation {
  JS = 'js',
  CSS = 'css'
}

export default (): Implementation => {
  const context = useContext(CreateResponsiveContext);
  return Implementation[context?.implementation.toUpperCase()];
};
