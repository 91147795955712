import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
/*
 * The ScopedCssBaseline MUST be imported before app code;
 * if this gets imported afterwards, we see box-sizing conflicts.
 * See https://material-ui.com/components/css-baseline/#scoping-on-children
*/
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline/ScopedCssBaseline';
import { ThemeProvider } from '@material-ui/core';
import theme from '../../../phc-web/app/materialUi/theme';
import { getEmbeddedStore } from "../embeddedStore";


export default function Embed({ children }: { children: ReactNode }) {
    const store = getEmbeddedStore();

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme({htmlFontSize: 10})}>
                <ScopedCssBaseline>
                    { children }
                </ScopedCssBaseline>
            </ThemeProvider>
        </Provider>
    );
}
