import { combineReducers } from 'redux';
import localizationUi from './localization/uiReducers';
import localizationDomain from './localization/domainReducers';

const rootReducer = combineReducers({
  domain: combineReducers({
    localization: localizationDomain
  }),
  presentational: combineReducers({
    localization: localizationUi
  })
});

export default rootReducer;
