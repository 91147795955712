enum Web1UiState {
    HOME = 'index.home',
    MENU = 'index.menu',
    MENU_PAGES = 'index.menu.page',
    MENU_PAGE_SUB_SECTIONS = 'index.menu.page.section',
    PIZZA_BUILDER = 'index.pizzabuilder',
    MASTER_DEAL = 'index.menu.deals.master',
    DEALS = 'index.menu.deals.step',
    ALL_UPSELLS = 'index.allupsell',
    ORDER_SUMMARY = 'index.order',
    DASHBOARD = 'index.dashboard',
    CHECKOUT_AUTH = 'index.auth',
    CHECKOUT_DETAILS = 'index.codetails',
    PAYMENT = 'index.payment'
}

export default Web1UiState;
