import React from "react";
import LocalizationRail from '../../../phc-web/app/localization/localizationRail/LocalizationRail';
import Modal from "../../../phc-web/app/common/modal";
import Embed from "./embed";

export default function EmbeddedLocalization() {

    return (
        <Embed>
            <LocalizationRail />
            <Modal />
        </Embed>
    );
}
