import { Action, combineReducers } from 'redux';
import {
  CARRYOUT_SEARCH_SUCCESS,
  DELIVERY_SEARCH_SUCCESS,
  OPEN_LOCALIZATION_RAIL,
  CLOSE_LOCALIZATION_RAIL,
  SHOW_SEARCH_IN_RAIL,
  SWITCH_TO_CARRYOUT,
  SWITCH_TO_DELIVERY,
  SHOW_LOADING_INDICATOR,
  HIDE_LOADING_INDICATOR,
  CLOSE_MODAL,
  OPEN_MODAL,
  LOAD_SAVED_ADDRESSES
} from './actionTypes';
import {
  CarryoutSearchSuccess,
  DeliverySearchSuccess,
  RouteToAfterLocalization
} from './localizeActions';
import { Occasion } from './constants';

const localizationRail = (
  state: {
    isOpen: boolean,
    routeTo: string | undefined
  } = { isOpen: false, routeTo: undefined },
  action: Action
) => {
  switch (action.type) {
    case OPEN_LOCALIZATION_RAIL: {
      return {
        isOpen: true,
        routeTo: (action as RouteToAfterLocalization).routeTo
      };
    }
    case CLOSE_LOCALIZATION_RAIL: {
      return {
        isOpen: false,
        routeTo: undefined
      };
    }
    default: {
      return state;
    }
  }
};

export const rail = combineReducers({
  options: localizationRail
});

const occasion = (state: string = Occasion.CARRYOUT, action: Action) => {
  switch (action.type) {
    case CLOSE_LOCALIZATION_RAIL:
    case SWITCH_TO_CARRYOUT: {
      return Occasion.CARRYOUT;
    }
    case SWITCH_TO_DELIVERY: {
      return Occasion.DELIVERY;
    }
    default: {
      return state;
    }
  }
};

const searchInRail = (state: boolean = true, action: Action) => {
  switch (action.type) {
    case CARRYOUT_SEARCH_SUCCESS:
    case DELIVERY_SEARCH_SUCCESS: {
      return false;
    }
    case CLOSE_LOCALIZATION_RAIL:
    case SHOW_SEARCH_IN_RAIL: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const previousSearchDetails = (
  state: CarryoutSearchDetails | DeliveryAddress = {}, action: Action
) => {
  switch (action.type) {
    case DELIVERY_SEARCH_SUCCESS: {
      const fullAddress = action as DeliverySearchSuccess;
      return {
        address: fullAddress.address,
        address2: fullAddress.address2,
        city: fullAddress.city,
        state: fullAddress.state,
        zipcode: fullAddress.zipcode
      } as DeliveryAddress;
    }
    case CARRYOUT_SEARCH_SUCCESS: {
      const {
        zipcode, city, state
      } = action as CarryoutSearchSuccess;
      return {
        zipcode, city, state
      } as CarryoutSearchDetails;
    }
    case CLOSE_LOCALIZATION_RAIL: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const isSearchingIndicator = (
  state: boolean = false, action: Action
) => {
  switch (action.type) {
    case SHOW_LOADING_INDICATOR:
      return true;
    case HIDE_LOADING_INDICATOR:
      return false;
    default:
      return state;
  }
};

const modalOpen = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return true;
    }
    case CLOSE_MODAL: {
      return false;
    }
    default:
      return state;
  }
};

const modalData = (state: ModalContent | {} = {}, action: Action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return (action as any).data;
    }
    default:
      return state;
  }
};

const modal = combineReducers({
  open: modalOpen,
  data: modalData
});

const suggestedDeliveryAddresses = (
  state: Array<DeliveryAddress> = null,
  action: DeliverySearchSuccess
) => {
  switch (action.type) {
    case DELIVERY_SEARCH_SUCCESS: {
      return action.deliverySearchResults.deliveryAddresses || null;
    }
    default:
      return state;
  }
};

const savedAddresses = (state: SavedAddress[] = [], action: Action) => {
  switch (action.type) {
    case LOAD_SAVED_ADDRESSES: {
      return (action as any).addresses;
    }
    default:
      return state;
  }
};

const localizationUi = combineReducers({
  rail,
  searchInRail,
  occasion,
  previousSearchDetails,
  isSearchingIndicator,
  modal,
  savedAddresses,
  suggestedDeliveryAddresses
});

export default localizationUi;
