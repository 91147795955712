export enum StoreTileHeaderType {
  PROMISE_TIME,
  OCCASION_NOT_AVAILABLE,
  ONLINE_NOT_AVAILABLE,
  STORE_CLOSED
}

export enum StoreTileButtonType {
  CONTINUE,
  PREORDER,
  SWITCH_OCCASION,
  NO_BUTTON,
  DELIVER_WITHOUT_BEER
}

export enum StoreStatus {
  'INACTIVE'= 'I',
  'TEMP_CLOSED'= 'T',
  'TEMP_CLOSED_WITH_REASON'= 'N',
  'PERM_OFFLINE'= 'P',
  'DELIVERY_AND_CARRYOUT'= 'DC',
  'CARRYOUT_ONLY'= 'C',
  'DELIVERY_ONLY'= 'D',
  // Online but outside of carryout and delivery hours
  'ONLINE_BUT_OUTSIDE_STORE_HOURS'= ''
}
