import React, { useEffect, useState } from 'react';
import {
  Grid, InputLabel, makeStyles, createStyles, Hidden, IconButton, Theme, Button, CircularProgress
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm } from 'react-hook-form';
import OutlineTextInput from '../../common/OutlineTextInput';
import {
  getInputsFromGooglePlace,
  getCityStateZipFromCarryoutInput,
  carryoutToString
} from '../common/utils';
import {
  initGoogleAutoSuggest,
  removeGoogleAutocomplete,
  geocodeGooglePlace
} from '../common/google/autoComplete';
import CurrentLocationIcon from '../icons/CurrentLocationIcon';
import CurrentLocationMobileIcon from '../icons/CurrentLocationMobileIcon';
import LinkButton from '../../common/LinkButton';
import { onSubmitHandler } from './OccasionForm';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    width: '100%'
  },
  occasion: {
    paddingBottom: '10px'
  },
  inputLabel: {
    fontFamily: 'open_sans_semi',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: '#131313',
    display: 'inline'
  },
  useMyLocationSection: {
    float: 'right',
    position: 'relative',
    padding: '4px 10px 5px 7px'
  },
  useMyLocationIcon: {
    position: 'relative',
    top: '2px'
  },
  useMyLocationMobIcon: {
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    }
  },
  validationError: {
    color: theme.palette.primary.main,
    marginTop: '4px'
  },
  search: {
    paddingTop: '10px'
  }
}));

export const onPlaceChanged = async (autocomplete, onFormChange, searchCarryout) => {
  const placeDetails = autocomplete.getPlace();
  const { city, state } = getInputsFromGooglePlace(placeDetails);
  const { lat, lng } = await geocodeGooglePlace(placeDetails.place_id);
  searchCarryout({
    city, state, lat, lng
  });
  // update input field with city, state selection
  onFormChange({
    city,
    state,
    lat,
    lng
  });
};

interface CarryoutFormFieldsProps {
  searchCarryout: (options: CarryoutSearchDetails) => void;
  searchCarryoutByLatLong: () => void;
  isSearchingIndicator: boolean;
  initialCarryoutState: CarryoutSearchDetails;
  switchToSavedAddresses: () => void;
  showUseSavedAddressButton: boolean;
}

export default function CarryoutFormFields(props: CarryoutFormFieldsProps) {
  const {
    searchCarryout,
    searchCarryoutByLatLong,
    isSearchingIndicator,
    initialCarryoutState,
    switchToSavedAddresses,
    showUseSavedAddressButton
  } = props;
  const cityStateId = 'carryout-city-state-zip';
  const [autoCompleteActive, setAutoCompleteActive] = useState(false);
  const [carryoutAddress, setCarryoutAddress] = useState(initialCarryoutState);
  const classes = useStyles();
  const {
    handleSubmit, register, errors, clearError
  } = useForm();

  const carryoutAddressValue = carryoutToString(carryoutAddress);
  const isValidationError: boolean = !!errors?.cityStateZip?.type;

  useEffect(() => function cleanup() {
    removeGoogleAutocomplete(cityStateId);
  }, []);

  const onFormChange = (carryoutInput) => {
    setCarryoutAddress(carryoutInput);
  };

  async function onFormChangeHandler(input) {
    const { city, state, zipcode } = getCityStateZipFromCarryoutInput(input);
    onFormChange({ city, state, zipcode });

    const isNumeric = (char: string) => /\d/.test(char);

    if (input.length > 2 && !isNumeric(input.charAt(0))) {
      // initialize autocomplete only once to avoid duplicate dropdowns
      if (!autoCompleteActive) {
        const options = {
          inputId: cityStateId,
          types: '(cities)',
          onPlaceChanged,
          onFormChange,
          searchAction: searchCarryout
        };
        initGoogleAutoSuggest(options);
        setAutoCompleteActive(true);
      }
    }
  }

  function validateCityStateZip(input: string): (string | true) {
    const trimmedInput = input.trim();

    let isCityState;
    const isZip = /^\d{5}$/.test(trimmedInput);
    const containsUsa = /USA/i.test(trimmedInput);
    const isNumberOnly = /^\d+$/.test(trimmedInput);

    if (containsUsa) {
      // eslint-disable-next-line no-useless-escape
      isCityState = /^[\w\s\.]+,[\w\s\.]+, USA$/i.test(trimmedInput);
    } else {
      // eslint-disable-next-line no-useless-escape
      isCityState = /^[\w\s\.]+,[\w\s\.]+$/i.test(trimmedInput);
    }

    if (!isZip && !isCityState) {
      if (!isZip && isNumberOnly) {
        return 'Enter a valid zip code';
      }
      return 'Enter a valid city, state or try a zip code';
    }

    return true;
  }

  const submitButton = (
    <Button data-testid="search" type="submit" variant="contained" color="primary" disableRipple fullWidth>
      {isSearchingIndicator ? <CircularProgress size={24} /> : 'Search'}
    </Button>
  );

  const doesCityStateInputValueMatchState = (
    inputValue: { city?: string, state?: string }
  ) => (
    carryoutAddress.city === inputValue.city
      && carryoutAddress.state === inputValue.state
  );

  const onSubmit = (formData, event) => {
    onSubmitHandler(event, () => {
      const inputFieldValue = getCityStateZipFromCarryoutInput(formData.cityStateZip);

      /*
       * When a city, state is selected from the Google Autocomplete dropdown, the api replaces the
       * input field with a new value without triggering a change event in the input field.
       * Therefore, what we have in the state will differ from the actual value in the input field.
       * If the values do not match, then that implies that the Google API has taken over, and the
       * callback will do the carryout search.
       */
      if (doesCityStateInputValueMatchState(inputFieldValue) || inputFieldValue.zipcode) {
        const { city, state, zipcode } = inputFieldValue;
        searchCarryout({ city, state, zipcode });
      }
    });
  };

  const onGPSClick = () => {
    clearError();
    searchCarryoutByLatLong();
  };

  return (
    <form className={classes.content} onSubmit={handleSubmit(onSubmit)}>
      <Grid item className={classes.occasion} xs={12}>
        <InputLabel className={classes.inputLabel} htmlFor={cityStateId}>
          City, state or zip code
          <Hidden mdDown>
            <Grid className={classes.useMyLocationSection}>
              <LinkButton
                testId="desktop-location-icon"
                aria-label="desktop location icon"
                onClick={onGPSClick}
                startIcon={<CurrentLocationIcon className={classes.useMyLocationIcon} />}
              >Use GPS
              </LinkButton>
            </Grid>
          </Hidden>
        </InputLabel>
        <OutlineTextInput
          onChange={onFormChangeHandler}
          id={cityStateId}
          name="cityStateZip"
          register={register({
            required: true,
            validate: validateCityStateZip
          })}
          error={isValidationError}
          testId="carryout-city-state-zip"
          defaultValue={carryoutAddressValue}
          icon={{
            endAdornment: (
              <Hidden lgUp>
                <InputAdornment position="start">
                  <IconButton
                    data-testid="mobile-location-icon"
                    aria-label="mobile location icon"
                    classes={{ root: classes.useMyLocationMobIcon }}
                    disableRipple
                    onClick={onGPSClick}
                    edge="end"
                  >
                    <CurrentLocationMobileIcon />
                  </IconButton>
                </InputAdornment>
              </Hidden>
            )
          }}
        />
        {errors?.cityStateZip?.type === 'required' && (
          <div className={classes.validationError} data-testid="carryout-citystatezip-required">
            Enter a valid city, state or try a zip code
          </div>
        )}
        {errors?.cityStateZip?.type === 'validate' && (
          <div className={classes.validationError} data-testid="carryout-citystatezip-validate">
            {errors.cityStateZip.message}
          </div>
        )}
      </Grid>
      {showUseSavedAddressButton
        && (
          <Grid>
            <LinkButton
              testId="switchto-saved-address"
              onClick={switchToSavedAddresses}
            >
              Use a saved address
            </LinkButton>
          </Grid>
        )}
      <Grid className={classes.search} container item xs={12} alignItems="flex-end">
        {submitButton}
      </Grid>
    </form>
  );
}
