import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkButton from '../../common/LinkButton';
import { Occasion } from '../constants';

const styles = makeStyles(() => createStyles({
  label: {
    width: '100%',
    color: '#131313',
    fontFamily: 'open_sans_semi',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
    letterSpacing: 'normal',
    display: 'inline'
  },
  selectBox: {
    border: '1px solid #131313',
    borderRadius: '8px',
    padding: '10px',
    display: 'block',
    width: '100%',
    lineHeight: '14px',
    '&:focus': {
      borderRadius: '8px'
    },
    marginBottom: '10px',
    fontSize: '14px'
  },
  selectBoxIcon: {
    top: 'calc(50% - 20px)',
    right: '5px',
    height: '30px',
    width: '30px',
    position: 'absolute',
    pointerEvents: 'none'
  },
  search: {
    paddingTop: '10px'
  }
}));

interface SavedAddressPickerProps {
  savedAddresses: SavedAddress[];
  switchToFormFields: () => void;
  occasion: Occasion;
  searchDelivery: (options: DeliveryAddress, isSavedAddress: boolean) => void;
  searchCarryout: (options: CarryoutSearchDetails, isSavedAddress: boolean) => void;
  isSearchingIndicator: boolean;
}

const SavedAddressOption = (savedAddress) => (
  <option
    key={savedAddress.index}
    value={savedAddress.index}
  >
    {savedAddress.address} {savedAddress.name && `(${savedAddress.name})`}
  </option>
);

const SavedAddressPicker = (props: SavedAddressPickerProps) => {
  const classes = styles();
  const {
    savedAddresses, switchToFormFields, searchDelivery, searchCarryout, occasion,
    isSearchingIndicator
  } = props;
  const savedAddressOptions = savedAddresses
    .filter((address) => address.address)
    .map(SavedAddressOption);

  const firstSavedAddressIndex = savedAddresses.length > 0 && savedAddresses[0].index;
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(firstSavedAddressIndex);

  const search = () => {
    const address = savedAddresses
      .find((savedAddress) => selectedAddressIndex === savedAddress.index);

    if (occasion === Occasion.DELIVERY) {
      const deliverySearch: DeliveryAddress = {
        address: address.address,
        address2: address.addressTwo,
        zipcode: address.zipcode,
        city: address.city,
        state: address.state
      };
      searchDelivery(deliverySearch, true);
    } else {
      const carryoutSearch: CarryoutSearchDetails = {
        zipcode: address.zipcode,
        city: address.city,
        state: address.state
      };
      searchCarryout(carryoutSearch, true);
    }
  };

  const labelText = occasion === Occasion.DELIVERY
    ? 'Saved address'
    : 'Find a store near a saved address';

  return (
    <>
      <InputLabel htmlFor="savedAddressPicker" classes={{ root: classes.label }}>
        {labelText}
      </InputLabel>
      <FormControl style={{ width: '100%' }}>
        <Select
          data-testid="saved-address-selectbox"
          disableUnderline
          native
          label="Saved Address Picker"
          inputProps={{
            name: 'savedAddress',
            id: 'savedAddressPicker',
            className: classes.selectBox
          }}
          IconComponent={() => (
            <ExpandMoreIcon
              classes={{ root: classes.selectBoxIcon }}
              data-testid="expand-more-icon"
            />
          )}
          onChange={(event: any) => setSelectedAddressIndex(parseInt(event.target.value, 10))}
        >
          {savedAddressOptions}
        </Select>
      </FormControl>
      <Grid>
        <LinkButton
          testId="use-new-location-button"
          onClick={switchToFormFields}
        >
          Use a new location
        </LinkButton>
      </Grid>
      <Grid className={classes.search} container item xs={12} alignItems="flex-end">
        <Button data-testid="saved-address-search" type="submit" variant="contained" color="primary" disableRipple fullWidth onClick={search}>
          {isSearchingIndicator ? <CircularProgress size={24} /> : 'Search'}
        </Button>
      </Grid>
    </>
  );
};

export default SavedAddressPicker;
