import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid, makeStyles, createStyles, Theme
} from '@material-ui/core';
import {
  selectDeliveryStore,
  switchToCarryout,
  switchToDelivery,
  searchDeliveryByLatLng
} from '../actions';
import StoreTile from './StoreTile';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import { Occasion } from '../constants';
import LinkButton from '../../common/LinkButton';
import DeliveryAddresses from './DeliveryAddresses';
import dataAnalytics from '../../dataAnalytics';
import { storeResultsAnalytics, storeResultsViewButtonAnalytics } from '../../dataAnalytics/dataAnalyticsHelper';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  storesNear: {
    fontFamily: 'sharp_sans_semi',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1.33,
    margin: '24px 0 16px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px',
      marginTop: '16px'
    }
  },
  storeGroup: {
    marginTop: '24px'
  },
  line: {
    color: '#cfcecc',
    marginLeft: '8px',
    marginRight: '8px'
  },
  storeTile: {
    paddingBottom: '16px'
  }
}));

interface DeliveryResultsProps {
  address: DeliveryAddress;
  stores: Array<StoreDetail>;
  selectDeliveryStore(options:
    { storeNumber: string,
      deliveryAddress: DeliveryAddress,
      removeAlcohol: boolean
    });
  searchDeliveryByLatLng: (options: DeliveryAddress, skipGeocode: boolean) => void;
  switchToDelivery: () => void;
  switchToCarryout: () => void;
  onEdit?: () => void;
  suggestedDeliveryAddresses: Array<DeliveryAddress>;
}

function DeliveryResults({
  address,
  stores,
  selectDeliveryStore,
  onEdit,
  searchDeliveryByLatLng,
  switchToCarryout,
  switchToDelivery,
  suggestedDeliveryAddresses
}: DeliveryResultsProps) {
  const classes = useStyles();

  useEffect(() => {
    dataAnalytics.push(storeResultsAnalytics('Delivery',
      { zipcode: address.zipcode },
      stores.length));
  });

  const onContinue = (storeNum: string, removeAlcohol: boolean = false) => {
    selectDeliveryStore({ storeNumber: storeNum, deliveryAddress: address, removeAlcohol });
  };

  const onEditClick = () => {
    onEdit();
    switchToDelivery();
    dataAnalytics.push(storeResultsViewButtonAnalytics('Delivery', 'Edit location'));
  };

  const onSwitchClick = () => {
    onEdit();
    switchToCarryout();
    dataAnalytics.push(storeResultsViewButtonAnalytics('Delivery', 'Change to carryout'));
  };

  if (suggestedDeliveryAddresses) {
    return (
      <DeliveryAddresses
        suggestedAddresses={suggestedDeliveryAddresses}
        userAddress={address}
        onEditClick={onEditClick}
        onSwitchClick={onSwitchClick}
        searchDelivery={searchDeliveryByLatLng}
      />
    );
  }

  return address && (
  <Grid wrap="nowrap" className={classes.content} data-testid="deliveryResults" container direction="column" spacing={0}>
    <Grid item data-testid="delivery-info" className={classes.storesNear}>
      {address.address} {address.address2}
      <br />
      {address.city}{address.state && <span>, {address.state}</span>} {address.zipcode}
    </Grid>
    <Grid item>
      <LinkButton testId="edit-location" onClick={onEditClick}>Edit location</LinkButton>
      <span className={classes.line}>|</span>
      <LinkButton testId="change-occasion" onClick={onSwitchClick}>Change to carryout</LinkButton>
    </Grid>
    <Grid wrap="nowrap" item container className={classes.storeGroup} direction="column" alignItems="stretch">
      {
            stores.map((store, index) => (
              <Grid item key={index} className={classes.storeTile}>
                <StoreTile
                  index={index}
                  occasion={Occasion.DELIVERY}
                  store={store}
                  onContinue={onContinue}
                  onSwitchOccasion={onSwitchClick}
                />
              </Grid>
            ))
          }
    </Grid>
  </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  address: state.presentational.localization.previousSearchDetails,
  stores: state.domain.localization.stores,
  suggestedDeliveryAddresses: state.presentational.localization.suggestedDeliveryAddresses
});

const mapDispatchToProps = {
  selectDeliveryStore,
  switchToCarryout,
  switchToDelivery,
  searchDeliveryByLatLng
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryResults);
