export default {
  "phone": "(925) 726-3350",
  "defaultPaymentType": "GC",
  "cc_Card_default_last4": "1111",
  "cc_Card_default_name": "rtjhtrhr reht",
  "cc_Card_default_index": 1,
  "cc_Card_default_exp_month": "11",
  "cc_Card_default_exp_year": "2034",
  "cc_Card_default_exp_type": "VI",
  "cc_card_default_zipcode": "75024",
  "saved_cards_defaultCount": 1,
  "saved_cards_Count": 1,
  "gc_card_default_last4": "9755",
  "gc_default_balance": "$0.00",
  "gc_default_count": 1,
  "gc_default_idx": 1,
  "birthdaySet": 1,
  "email": "hutrewards1@yopmail.com",
  "firstname": "Staging",
  "lastname": "Accchange",
  "phoneExt": "",
  "savedLocations": [{
    "name": "House",
    "address": "120 STAR",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60610",
    "comment": "PIZZA PIZZA",
    "phone": "925-726-3350",
    "phoneExt": "",
    "default": "1",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 27,
    "idx": 1,
    "address_two": "APT 500"
  }, {
    "name": "Apartment",
    "address": "2700 W CERMAK RD",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60608",
    "comment": "WQRASDGCB",
    "phone": "814-584-5984",
    "phoneExt": "",
    "default": "",
    "dwellCode": "A",
    "locationName": "",
    "aryKey": 2,
    "idx": 2,
    "address_two": "SUITE 100"
  }, {
    "name": "",
    "address": "2512 VERSAILLES AVE",
    "city": "NAPERVILLE",
    "state": "IL",
    "zip": "60540",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "B",
    "locationName": "",
    "aryKey": 3,
    "idx": 3,
    "address_two": ""
  }, {
    "name": "",
    "address": "200 MEMORY LN",
    "city": "PLANO",
    "state": "TX",
    "zip": "75024",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "C",
    "locationName": "",
    "aryKey": 4,
    "idx": 4,
    "address_two": ""
  }, {
    "name": "",
    "address": "TYJTYJ",
    "city": "NOT SET",
    "state": "**",
    "zip": "13513",
    "comment": "",
    "phone": "651-651-6316",
    "phoneExt": "",
    "default": "",
    "dwellCode": "H",
    "locationName": "",
    "aryKey": 5,
    "idx": 5,
    "address_two": ""
  }, {
    "name": "",
    "address": "100 MEMORY LN",
    "city": "PLANO",
    "state": "TX",
    "zip": "75024",
    "comment": "PIZZA HUT",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "I",
    "locationName": "",
    "aryKey": 6,
    "idx": 6,
    "address_two": ""
  }, {
    "name": "",
    "address": "100 MEMORY LN",
    "city": "PLANO",
    "state": "TX",
    "zip": "75024",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "L",
    "locationName": "",
    "aryKey": 7,
    "idx": 7,
    "address_two": ""
  }, {
    "name": "",
    "address": "2900 W 15TH ST",
    "city": "PLANO",
    "state": "TX",
    "zip": "75024",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "D",
    "locationName": "",
    "aryKey": 8,
    "idx": 8,
    "address_two": ""
  }, {
    "name": "",
    "address": "5262 EHRLICH RD",
    "city": "TAMPA",
    "state": "FL",
    "zip": "33624",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "M",
    "locationName": "",
    "aryKey": 9,
    "idx": 9,
    "address_two": ""
  }, {
    "name": "",
    "address": "3452 W DEVON AVE",
    "city": "LINCOLNWOOD",
    "state": "IL",
    "zip": "60712",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "S",
    "locationName": "",
    "aryKey": 10,
    "idx": 10,
    "address_two": ""
  }, {
    "name": "",
    "address": "100 PENNSYLVANIA AVE",
    "city": "WASHINGTON",
    "state": "DC",
    "zip": "20500",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "O",
    "locationName": "",
    "aryKey": 13,
    "idx": 11,
    "address_two": ""
  }, {
    "name": "",
    "address": "102 E QUINCY ST",
    "city": "PITTSBURG",
    "state": "KS",
    "zip": "66762",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "",
    "locationName": "",
    "aryKey": 14,
    "idx": 12,
    "address_two": ""
  }, {
    "name": "",
    "address": "3450 W DEVON AVE",
    "city": "LINCOLNWOOD",
    "state": "IL",
    "zip": "60712",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 15,
    "idx": 13,
    "address_two": ""
  }, {
    "name": "",
    "address": "99 PENNSYLVANIA AVE",
    "city": "WASHINGTON",
    "state": "DC",
    "zip": "20500",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 16,
    "idx": 14,
    "address_two": ""
  }, {
    "name": "",
    "address": "3451 W DEVON AVE",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60659",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 20,
    "idx": 15,
    "address_two": ""
  }, {
    "name": "",
    "address": "9461 SW ALLEN BLVD",
    "city": "BEAVERTON",
    "state": "OR",
    "zip": "97008",
    "comment": "",
    "phone": "116-565-4646",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 23,
    "idx": 16,
    "address_two": ""
  }, {
    "name": "",
    "address": "111 MEMORY LN",
    "city": "PLANO",
    "state": "TX",
    "zip": "75024",
    "comment": "",
    "phone": "116-565-4647",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 24,
    "idx": 17,
    "address_two": ""
  }, {
    "name": "",
    "address": "200 MEMORY LN",
    "city": "PLANO",
    "state": "TX",
    "zip": "75024",
    "comment": "",
    "phone": "925-726-3350",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 26,
    "idx": 18,
    "address_two": "SUITE 555"
  }, {
    "name": "",
    "address": "120 STAR",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60610",
    "comment": "",
    "phone": "111-111-1111",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 28,
    "idx": 19,
    "address_two": ""
  }, {
    "name": "",
    "address": "120 STAR",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60610",
    "comment": "",
    "phone": "111-111-1111",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 29,
    "idx": 20,
    "address_two": "SUITE 500"
  }, {
    "name": "",
    "address": "120 STAR",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60610",
    "comment": "",
    "phone": "111-111-1111",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 35,
    "idx": 21,
    "address_two": "APT 100"
  }, {
    "name": "",
    "address": "3009 N CLARK ST",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60657",
    "comment": "",
    "phone": "111-111-1112",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 36,
    "idx": 22,
    "address_two": ""
  }, {
    "name": "HIUSEEE",
    "address": "5403 S WENTWORTH AVE",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60609",
    "comment": "",
    "phone": "578-689-7000",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 39,
    "idx": 23,
    "address_two": ""
  }, {
    "name": "",
    "address": "3044 N PULASKI RD",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60641",
    "comment": "",
    "phone": "345-768-9000",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 40,
    "idx": 24,
    "address_two": ""
  }, {
    "name": "",
    "address": "4280 N CENTRAL AVE",
    "city": "CHICAGO",
    "state": "IL",
    "zip": "60634",
    "comment": "",
    "phone": "365-587-7998",
    "phoneExt": "",
    "default": "",
    "dwellCode": "P",
    "locationName": "",
    "aryKey": 41,
    "idx": 25,
    "address_two": ""
  }, {"address_two": ""}, {"address_two": ""}, {"address_two": ""}, {"address_two": ""}, {"address_two": ""}],
  "savedCards": {
    "displayAddCCButton": 1,
    "ccCardCount": 1,
    "giftCardCount": 0,
    "defaultCount": 0,
    "ccCards": [{
      "type": "VI",
      "last4": "1111",
      "exp_month": "11",
      "exp_year": "2034",
      "name": "rtjhtrhr reht",
      "zipcode": "75024",
      "idx": 1,
      "isTPGC": "N",
      "expired": false
    }],
    "default": []
  },
  "giftCardCount": 0
};


