import { Dispatch } from 'redux';
import localizationService from './services/localizationService';
import accountService from './services/accountService';
import {
  CARRYOUT_SEARCH_SUCCESS,
  DELIVERY_SEARCH_SUCCESS,
  OPEN_LOCALIZATION_RAIL,
  CLOSE_LOCALIZATION_RAIL,
  SHOW_SEARCH_IN_RAIL,
  SWITCH_TO_DELIVERY,
  SWITCH_TO_CARRYOUT,
  SHOW_LOADING_INDICATOR,
  HIDE_LOADING_INDICATOR,
  CLOSE_MODAL,
  OPEN_MODAL,
  LOAD_SAVED_ADDRESSES,
  SELECT_STORE
} from './actionTypes';
import dataAnalytics from '../dataAnalytics';
import getGeoLocation from './common/geoLocation/getGeoLocation';
import { carryoutToString } from './common/utils';
import router from '../router';
import cartService from './services/cartService';
import {
  onOpenLocalizationRail,
  storeSearchFormAnalytics,
  storeSearchSuccessAnalytics,
  storeSearchNoResultsAnalytics,
  storeSearchNoResultsActionAnalytics,
  modalErrorPopupAnalytics,
  modalErrorPopupClickAnalytics
} from '../dataAnalytics/dataAnalyticsHelper';
import newRelicAdapter from '../newRelic/newRelicAdapter';
import connectionConfig from './services/connectionConfig';
import { Occasion } from './constants';
import logger from '../common/logger';

interface AsyncDispatch {
  (dispatch: Dispatch, getState?: () => any): Promise<void>
}

export const carryoutSearchSuccess = (
  options: CarryoutSearchDetails,
  carryoutSearchResults: any
) => ({
  type: CARRYOUT_SEARCH_SUCCESS,
  zipcode: options.zipcode,
  lat: options.lat,
  lng: options.lng,
  city: options.city,
  state: options.state,
  carryoutSearchResults
});

export const closeModal = (
) => ({
  type: CLOSE_MODAL
});

export const openModal = (
  data: any
) => ({
  type: OPEN_MODAL,
  data
});

export const deliverySearchSuccess = (
  address: string,
  address2: string,
  city: string,
  state: string,
  zipcode: string,
  deliverySearchResults: any
) => ({
  type: DELIVERY_SEARCH_SUCCESS,
  address,
  address2,
  city,
  state,
  zipcode,
  deliverySearchResults
});

export const loadSavedAddresses = (addresses: SavedAddress[]) => ({
  type: LOAD_SAVED_ADDRESSES,
  addresses
});

export const openRail = (
  options?: { routeTo: string | undefined }
): AsyncDispatch => async (dispatch: Dispatch) => {
  const savedAddresses = await accountService.getUserSavedAddresses();
  dispatch(loadSavedAddresses(savedAddresses));

  dispatch({
    type: OPEN_LOCALIZATION_RAIL,
    routeTo: options?.routeTo
  });
  dataAnalytics.push(onOpenLocalizationRail(savedAddresses.length > 0));
  newRelicAdapter.addPageAction('web2-localization-rail-open');

  logger.error('Sample error log that will only log client side');
};

export const closeRail = () => ({
  type: CLOSE_LOCALIZATION_RAIL
});

export const switchToDelivery = () => ({
  type: SWITCH_TO_DELIVERY
});

export const switchToCarryout = () => ({
  type: SWITCH_TO_CARRYOUT
});

const showLoadingIndicator = () => ({
  type: SHOW_LOADING_INDICATOR
});

const hideLoadingIndicator = () => ({
  type: HIDE_LOADING_INDICATOR
});

export const showSearchInRail = () => ({
  type: SHOW_SEARCH_IN_RAIL
});

export const selectStore = (
  storeNumber: string,
  occasion?: string,
  store?: StoreDetail
) => ({
  type: SELECT_STORE,
  storeNumber,
  store,
  occasion
});

const isDifferentStore = (
  storeNumber: string,
  previousStoreNumber: string
) => storeNumber !== previousStoreNumber;

const showConnectivityIssueModal = () => openModal({
  title: 'We\'re sorry!',
  body: 'We\'re currently experiencing connectivity issues. Please try entering your location again.',
  cta: {
    text: 'OK'
  }
});

export function searchCarryout(
  options: CarryoutSearchDetails,
  isSavedAddress?: boolean
): AsyncDispatch {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoadingIndicator());
      const carryoutSearchResults = await localizationService.findCarryoutStores(options);
      dispatch(carryoutSearchSuccess(options, carryoutSearchResults));
      dispatch(hideLoadingIndicator());
      dataAnalytics.push(storeSearchSuccessAnalytics('Carryout', isSavedAddress));
      newRelicAdapter.addPageAction('web2-search-carryout-stores');
    } catch (error) {
      dispatch(hideLoadingIndicator());

      if (error.message === connectionConfig.requestTimeoutErrorMessage) {
        dispatch(showConnectivityIssueModal());
        return;
      }

      dispatch(openModal({
        title: 'We\'re sorry!',
        body: `There are no available Pizza Huts near: ${carryoutToString(options)}`,
        cta: {
          text: 'ENTER NEW LOCATION',
          callback: () => dataAnalytics.push(
            storeSearchNoResultsActionAnalytics('Carryout', 'Enter New Address', isSavedAddress)
          )
        }
      }));
      dataAnalytics.push(storeSearchNoResultsAnalytics('Carryout', options, isSavedAddress));
    }
  };
}

const handleGeoLocationError = (dispatch: Dispatch) => {
  const title = 'Geolocation error';
  const body = 'We are currently blocked from using your location, you must grant us permission.';

  dispatch(hideLoadingIndicator());
  dispatch(openModal({
    title,
    body,
    cta: { text: 'OK', callback: () => dataAnalytics.push(modalErrorPopupClickAnalytics(title, body, 'OK')) }
  }));
  dataAnalytics.push(modalErrorPopupAnalytics(title, body));
};

const handleCarryoutSearchError = (
  error: Error, dispatch: Dispatch, latlong: CarryoutSearchDetails
) => {
  dispatch(hideLoadingIndicator());

  if (error.message === connectionConfig.requestTimeoutErrorMessage) {
    dispatch(showConnectivityIssueModal());
    return;
  }

  dispatch(openModal({
    title: 'We\'re sorry!',
    body: 'There are no available Pizza Huts near you.',
    cta: {
      text: 'ENTER NEW LOCATION',
      callback: () => dataAnalytics.push(
        storeSearchNoResultsActionAnalytics('Carryout', 'Enter New Address')
      )
    }
  }));
  dataAnalytics.push(storeSearchNoResultsAnalytics('Carryout', latlong));
};

export const searchCarryoutByLatLong = (): AsyncDispatch => async (dispatch: Dispatch) => {
  dispatch(showLoadingIndicator());
  dataAnalytics.push(
    storeSearchFormAnalytics('Carryout', true)
  );
  await getGeoLocation()
    .then(async (latlong) => {
      await localizationService
        .findCarryoutStoresByLatLong(latlong)
        .then((carryoutSearchResults) => {
          dispatch(carryoutSearchSuccess(latlong, carryoutSearchResults));
          dispatch(hideLoadingIndicator());
          dataAnalytics.push(storeSearchSuccessAnalytics('Carryout'));
          newRelicAdapter.addPageAction('web2-search-carryout-stores');
        })
        .catch((error: Error) => handleCarryoutSearchError(error, dispatch, latlong));
    })
    .catch(() => handleGeoLocationError(dispatch));
};

const deliverySearch = async (
  dispatch: Dispatch,
  deliveryAddress: DeliveryAddress,
  findDeliveryStores: Function,
  isSavedAddress?: boolean
) => {
  try {
    dispatch(showLoadingIndicator());
    const deliverySearchResults = await findDeliveryStores();
    dispatch(deliverySearchSuccess(
      deliveryAddress.address,
      deliveryAddress.address2,
      deliveryAddress.city,
      deliveryAddress.state,
      deliveryAddress.zipcode,
      deliverySearchResults
    ));
    dispatch(hideLoadingIndicator());
    dataAnalytics.push(storeSearchSuccessAnalytics('Delivery', isSavedAddress));
    newRelicAdapter.addPageAction('web2-search-delivery-stores');
  } catch (err) {
    dispatch(hideLoadingIndicator());

    if (err.message === connectionConfig.requestTimeoutErrorMessage) {
      dispatch(showConnectivityIssueModal());
      return;
    }

    dispatch(openModal({
      title: 'We\'re Sorry!',
      body: 'There are no available Pizza Huts that can deliver to: '
        + `${deliveryAddress.address}, ${deliveryAddress.city}, ${deliveryAddress.state} ${deliveryAddress.zipcode}.`,
      cta: {
        text: 'ENTER NEW ADDRESS',
        callback: () => {
          dispatch(showSearchInRail());
          dataAnalytics.push(
            storeSearchNoResultsActionAnalytics('Delivery', 'Enter New Address', isSavedAddress)
          );
        }
      },
      altCta: {
        text: 'SWITCH TO CARRYOUT',
        callback: () => {
          dispatch(showSearchInRail());
          dispatch(switchToCarryout());
          dataAnalytics.push(
            storeSearchNoResultsActionAnalytics('Delivery', 'Switch To Carryout', isSavedAddress)
          );
        }
      }
    }));
    dataAnalytics.push(storeSearchNoResultsAnalytics(
      'Delivery',
      {
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        zipcode: deliveryAddress.zipcode
      },
      isSavedAddress
    ));
  }
};

export const searchDelivery = (
  options: { address: string, address2: string, city: string, state: string, zipcode: string },
  isSavedAddress?: boolean
): AsyncDispatch => async (dispatch: Dispatch) => {
  deliverySearch(
    dispatch,
    options,
    () => localizationService.findDeliveryStores(options),
    isSavedAddress
  );
};

export const searchDeliveryByLatLng = (
  deliveryAddress: DeliveryAddress, skipGeoCode: boolean
): AsyncDispatch => async (dispatch: Dispatch) => {
  deliverySearch(
    dispatch,
    deliveryAddress,
    () => localizationService.findDeliveryStoresByLatLong(deliveryAddress, skipGeoCode)
  );
};

export const selectCarryoutStore = (
  options: { storeNumber: string }
): AsyncDispatch => async (dispatch: Dispatch, getState: () => any) => {
  const previousStoreNumber = getState().domain.localization.localizedStore;
  const selectedStore = await localizationService.selectCarryoutStore(options);

  const route = getState().presentational.localization.rail.options.routeTo;
  const userIsLocalizingToADifferentStore = isDifferentStore(
    options.storeNumber,
    previousStoreNumber
  );

  if (route && userIsLocalizingToADifferentStore) {
    router.goToRoute(route);
  }

  dispatch(selectStore(options.storeNumber, Occasion.CARRYOUT, selectedStore || undefined));
  dispatch(closeRail());
  newRelicAdapter.addPageAction('web2-select-carryout-store');
};

export const selectDeliveryStore = (options: {
  storeNumber: string,
  deliveryAddress: DeliveryAddress
  removeAlcohol: boolean
}): AsyncDispatch => async (dispatch: Dispatch, getState: () => any) => {
  const previousStoreNumber = getState().domain.localization.localizedStore;

  if (options.removeAlcohol) await cartService.removeAlcoholFromCart();
  const selectedStore = await localizationService.selectDeliveryStore(
    options.storeNumber, options.deliveryAddress
  );

  const userIsLocalizingToADifferentStore = isDifferentStore(
    options.storeNumber,
    previousStoreNumber
  );

  const route = getState().presentational.localization.rail.options.routeTo;
  if (route && userIsLocalizingToADifferentStore) {
    router.goToRoute(route);
  }

  dispatch(selectStore(options.storeNumber, Occasion.DELIVERY, selectedStore || undefined));
  dispatch(closeRail());
  newRelicAdapter.addPageAction('web2-select-delivery-store');
};
