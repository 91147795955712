import { StoreStatus } from '../localizationRail/StoreTile/constants';

interface StoreDetailBuilder {
    storeNumber? : string;
    address? : string;
    city? : string;
    state?: string;
    zipcode?: string;
    promiseTime?: number;
    openTime?: string | boolean;
    closeTime?: string | boolean;
    splitOpenTime?: string;
    splitCloseTime?: string;
    landmark?: string;
    phoneNumber?: string;
    storeStatus?: string;
    acceptFutureOrders?: boolean;
    storeClosureReason?: string;
    lat?: number;
    long?: number;
    includeBeerWarning?: boolean;
}

export default function buildStoreDetail(overrides : StoreDetailBuilder = {}) : StoreDetail {
  return {
    storeNumber: '1',
    address: '100 E 6th St',
    city: 'Austin',
    state: 'TX',
    zipcode: '78701',
    promiseTime: 40,
    openTime: '10:00AM',
    closeTime: '11:00PM',
    phoneNumber: '5125551234',
    storeStatus: StoreStatus.DELIVERY_AND_CARRYOUT,
    acceptFutureOrders: false,
    storeClosureReason: '',
    lat: 34.5667,
    long: -77.835,
    ...overrides
  };
}
