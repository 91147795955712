import { createMuiTheme } from '@material-ui/core/styles';

interface ThemeOptions {
    htmlFontSize: number
}

export const largeStartBreakpoint = 'lg';
export const mobileStartBreakpoint = 'md';
export const smallMobileStartBreakpoint = 'sm';
export const extraSmallMobileStartBreakpoint = 'xs';

export default function createTheme({ htmlFontSize } : ThemeOptions) {
  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 361,
        md: 651,
        lg: 851,
        xl: 1100
      }
    },
    shape: {
      borderRadius: 8
    },
    typography: {
      // Tell Material-UI what the font-size on the html element is.
      htmlFontSize
    },
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#e52a33'
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      }
    },
    overrides: {
      MuiButton: {
        contained: {
          fontFamily: 'sharp_sans_semi',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: 1.5,
          letterSpacing: '1px',
          textAlign: 'center',
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          }
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#e52a33',
            boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)'
          },
          '&:active': {
            backgroundColor: '#d10a14',
            boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.3)'
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          fontFamily: 'open_sans',
          fontSize: '14px',
          lineHeight: 1.43,
          letterSpacing: 'normal',
          color: '#131313'
        }
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            fontSize: '10px'
          },
          body: {
            fontFamily: '"open_sans", sans serif',
            lineHeight: '21px'
          }
        }
      },
      MuiButtonBase: {
        root: {
          padding: 'unset'
        }
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: '#ffffff'
        }
      }
    }
  });
}
