import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Grid, makeStyles, createStyles, Theme
} from '@material-ui/core';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import StoreTile from './StoreTile';
import {
  selectCarryoutStore,
  switchToCarryout,
  switchToDelivery
} from '../actions';
import { Occasion } from '../constants';
import LinkButton from '../../common/LinkButton';
import Map from '../common/google/Map';
import { railHeaderHeight, railHeaderHeightMobileView } from './RailHeader';
import dataAnalytics from '../../dataAnalytics';
import { storeResultsAnalytics, storeResultsViewButtonAnalytics } from '../../dataAnalytics/dataAnalyticsHelper';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    marginTop: '0px'
  },
  content: {
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  storesNear: {
    fontFamily: 'sharp_sans_semi',
    fontSize: '18px',
    fontWeight: 600,
    margin: '24px 0 16px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px',
      marginTop: '16px'
    }
  },
  storeGroup: {
    marginTop: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '16px'
    }
  },
  line: {
    color: '#cfcecc',
    marginLeft: '8px',
    marginRight: '8px'
  },
  mapContainer: {
    position: 'sticky',
    top: railHeaderHeight,
    zIndex: 1,
    marginTop: '24px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '16px',
      top: railHeaderHeightMobileView
    }
  },
  storeTile: {
    paddingBottom: '16px',
    flexBasis: 'auto',
    flexShrink: 0
  }
}));

interface CarryoutResultsProps {
  zipcode: string;
  city: string;
  state: string;
  storesByDistance: Array<StoreDetail>;
  selectCarryoutStore(options: { storeNumber: string });
  switchToDelivery: () => void;
  switchToCarryout: () => void;
  onEdit?: () => void;
}

function CarryoutResults({
  zipcode, city, state, storesByDistance,
  selectCarryoutStore, onEdit, switchToCarryout, switchToDelivery
}: CarryoutResultsProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('store-tile-0');
  const [storesBySelection, setStoresBySelection] = useState(storesByDistance);
  const [selectedStoreNumber, setSelectedStoreNumber] = useState<string | undefined>();
  const storesNearRef = useRef();

  useEffect(() => {
    const isStoresBySelectionEmpty = !storesBySelection || storesBySelection.length === 0;
    if (isStoresBySelectionEmpty) setStoresBySelection(storesByDistance);
    if (storesByDistance.length) setSelectedStoreNumber(storesByDistance[0].storeNumber);
    dataAnalytics.push(storeResultsAnalytics('Carryout', {
      city, state, zipcode
    }, storesByDistance.length));
  }, [storesByDistance]);

  const onContinue = (storeNumber: string) => {
    selectCarryoutStore({ storeNumber });
  };

  const onEditClick = () => {
    onEdit();
    switchToCarryout();
    dataAnalytics.push(storeResultsViewButtonAnalytics('Carryout', 'Edit location'));
  };

  const onSwitchClick = () => {
    onEdit();
    switchToDelivery();
    dataAnalytics.push(storeResultsViewButtonAnalytics('Carryout', 'Change to delivery'));
  };

  const reorderStoresOnMarkerClick = (marker: GeometryLocation) => {
    const selectedStoreIndex = storesByDistance.findIndex(
      ({ lat, long }) => lat === marker.lat && long === marker.lng
    );
    const selectedStore = storesByDistance[selectedStoreIndex];

    const updatedStores = storesByDistance.filter(
      (store) => store.storeNumber !== selectedStore.storeNumber
    );
    updatedStores.unshift(selectedStore);

    setStoresBySelection(updatedStores);
    setSelectedStoreNumber(updatedStores[0].storeNumber);
    setExpanded('store-tile-0');

    (storesNearRef.current as any).scrollIntoView();
  };

  const selectStoreTile = (panel: string, isExpanded: string, storeNumber: string) => {
    setExpanded(isExpanded ? panel : '');
    setSelectedStoreNumber(storeNumber);
  };

  return (
    <>
      <Grid item data-testid="carryout-info" className={`${classes.storesNear} ${classes.content}`} ref={storesNearRef}>
        Stores near {zipcode || (city && state && `${city}, ${state}`) || 'me'}
      </Grid>
      <Grid className={classes.content} item>
        <LinkButton testId="edit-location" onClick={onEditClick}>Edit location</LinkButton>
        <span className={classes.line}>|</span>
        <LinkButton testId="change-occasion" onClick={onSwitchClick}>Change to delivery</LinkButton>
      </Grid>
      <Grid item className={classes.mapContainer}>
        <Map
          mapId="carryout-map"
          stores={storesByDistance}
          onMarkerClick={reorderStoresOnMarkerClick}
          highlightedMarker={selectedStoreNumber}
        />
      </Grid>
      <Grid container direction="column" spacing={0} className={`${classes.container} ${classes.content}`}>
        <Grid wrap="nowrap" item container className={classes.storeGroup} direction="column" alignItems="stretch">
          {
            storesBySelection.map((store, index) => (
              <Grid item xs={12} key={index} className={classes.storeTile}>
                <StoreTile
                  index={index}
                  store={store}
                  onContinue={onContinue}
                  selectStoreTile={selectStoreTile}
                  expandState={expanded}
                  expandable
                  onSwitchOccasion={onSwitchClick}
                  occasion={Occasion.CARRYOUT}
                />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state: any) => {
  const carryoutState = state.presentational.localization.previousSearchDetails.state;
  const {
    zipcode, city
  } = state.presentational.localization.previousSearchDetails;
  return {
    zipcode,
    city,
    state: carryoutState,
    storesByDistance: state.domain.localization.stores
  };
};

const mapDispatchToProps = {
  selectCarryoutStore,
  switchToCarryout,
  switchToDelivery
};
export default connect(mapStateToProps, mapDispatchToProps)(CarryoutResults);
