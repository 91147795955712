import React from 'react';
import StoreClosedBadge from './StoreClosedBadge';
import { OccasionString } from '../../constants';
import { StoreTileHeaderType } from './constants';
import transformPromiseTime from '../../common/transformPromiseTime';

interface StoreTileHeaderProps {
  store: StoreDetail;
  headerType: StoreTileHeaderType;
  occasion: string;
  index: number;
}

function StoreTileHeader({
  store,
  headerType,
  occasion,
  index
}: StoreTileHeaderProps) {
  switch (headerType) {
    case StoreTileHeaderType.OCCASION_NOT_AVAILABLE:
      return (
        <span data-testid={`local_rail-store_tile_${index}-${OccasionString[occasion].toLowerCase()}_not_available_banner`}>
          {`${OccasionString[occasion]} not available`}
        </span>
      );
    case StoreTileHeaderType.ONLINE_NOT_AVAILABLE:
      return (
        <span data-testid={`local_rail-store_tile_${index}-online_not_available_banner`}>
          Online not available
        </span>
      );
    case StoreTileHeaderType.PROMISE_TIME: {
      const promiseTime = transformPromiseTime(occasion, store.promiseTime, store.promiseTimeStale);

      return (
        <span data-testid={`local_rail-store_tile_${index}-promise_time_banner`}>
          {store.promiseTime && `Ready in ${promiseTime}`}
        </span>
      );
    }
    case StoreTileHeaderType.STORE_CLOSED:
      return (
        <StoreClosedBadge data-testid={`local_rail-store_tile_${index}-store_closed_banner`} />
      );
    default:
      return undefined;
  }
}

export default StoreTileHeader;
