import 'react-app-polyfill/ie11';
import '../../phc-web/app/polyfills/ie11';
import web1Embedder from './embedding-framework/web1Embedder';
import angularHost from './embedding-framework/angular-adapters/angularHost/';
import Web1EventListener from "./embedding-framework/angular-adapters/web1EventListener";
import { getEmbeddedStore } from "./embeddedStore";
import angularHostPollingAdapter
  from "./embedding-framework/angular-adapters/angularHostPollingAdapter";
import newRelicAdapter from "../../phc-web/app/newRelic/newRelicAdapter";

export default {
  start() {
    return this.startAsync().catch((e: Error) => {
      console.log('web2 error:', e);
      newRelicAdapter.noticeError(e);
    })
  },
  async startAsync() {
    const startingState = await angularHostPollingAdapter.checkIfAngularIsLoaded();

    web1Embedder.initializeWeb2();

    const store = getEmbeddedStore();
    angularHostPollingAdapter.checkLocalizedStore(store);

    web1Embedder.renderForRoute(startingState);
    angularHost.onUiStateChangeSuccess(function(event: any, toState: AngularUiRouterState, toParams: any, fromState: AngularUiRouterState, fromParams: any) {
      web1Embedder.renderForRoute(toState);
    });

    Web1EventListener.listen(store);
  }
}
