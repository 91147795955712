export default {
    "success": true,
    "to_cart": {
      "order": {
        "sideitem": {
          "1": "1~WS^12^NH^^TD~~"
        }
      },
      "total": "18.40",
      "subtotal": "13.99",
      "loyalty_removed": 0
    }
  }