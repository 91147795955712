import React, { MouseEvent } from 'react';
import {
  Grid, makeStyles, createStyles, Theme
} from '@material-ui/core';
import { StoreTileButtonType } from './constants';
import { OccasionString, Occasion } from '../../constants';
import dataAnalytics from '../../../dataAnalytics';
import { storeTileCtaAnalytics } from '../../../dataAnalytics/dataAnalyticsHelper';
import TextLinkWithCarat from '../../../common/TextLinkWithCarat';

const useStyles = makeStyles((theme: Theme) => createStyles({
  flexGrow: {
    flexGrow: 1
  },
  storeStatusMsg: {
    marginTop: '10px',
    color: theme.palette.primary.main,
    width: '100%',
    lineHeight: 1.43
  }
}));

interface StoreTileCtaProps {
  store: StoreDetail;
  index: number;
  onContinue: (storeNumber: string, removeAlcohol: boolean) => void;
  onSwitchOccasion: () => void;
  buttonType: StoreTileButtonType;
  occasion: Occasion.DELIVERY | Occasion.CARRYOUT;
  storeStatusMessage: string;
}

const StoreTileCta = ({
  store,
  index,
  onContinue,
  onSwitchOccasion,
  buttonType,
  occasion,
  storeStatusMessage
}: StoreTileCtaProps) => {
  const classes = useStyles();

  const onClick = (event: MouseEvent) => {
    if (buttonType === StoreTileButtonType.SWITCH_OCCASION) {
      // show delivery form in rail on 'Order Delivery'
      onSwitchOccasion();
    } else {
      const removeAlcohol = buttonType === StoreTileButtonType.DELIVER_WITHOUT_BEER;
      onContinue(store.storeNumber, removeAlcohol);
    }
    dataAnalytics.push(
      storeTileCtaAnalytics(occasion === Occasion.CARRYOUT ? OccasionString.C : OccasionString.D)
    );
    event.stopPropagation();
  };

  const getCtaText = () => {
    switch (buttonType) {
      case StoreTileButtonType.CONTINUE:
        return 'Continue';
      case StoreTileButtonType.PREORDER:
        return 'Pre-Order';
      case StoreTileButtonType.SWITCH_OCCASION:
        return `Order  ${occasion === Occasion.CARRYOUT ? OccasionString.D : OccasionString.C}`;
      case StoreTileButtonType.DELIVER_WITHOUT_BEER:
        return 'Deliver Without Beer';
      default:
        return undefined;
    }
  };

  const ctaText = getCtaText();

  return (
    <Grid className={classes.flexGrow}>
      {storeStatusMessage
        && (
          <Grid item data-testid={`local_rail-store_tile_${index}-status_message`} className={classes.storeStatusMsg}>
            {storeStatusMessage}
          </Grid>
        )}
      {ctaText
        && (
          <Grid>
            <TextLinkWithCarat
              testId={`store-tile-btn-${index}`}
              ariaLabel={`store tile btn ${index}`}
              text={ctaText}
              onClick={onClick}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default StoreTileCta;
