import newRelicAdapter from './newRelicAdapter';

export default function catchAndLogErrors(func : (...args) => void) {
  return (...args) => {
    try {
      func(...args);
    } catch (error) {
      newRelicAdapter.noticeError(error);
    }
  };
}
