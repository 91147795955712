export default {
    "location": [],
    "formattedSearchAddress": undefined,
    "localizingToSameStore": true,
    "address": {
      "boozeId": ""
    },
    "stores": [
        {
          "address": "203 Wolf Rd",
          "city": "Albany",
          "state": "NY",
          "zip": "12205",
          "country": "US",
          "phone": "(518)458-1221",
          "lat": "42.724002",
          "long": "-73.798797",
          "distance": "11.159",
          "StoreNumber": "034300",
          "OnlineOrdering": true,
          "WingStreet": false,
          "Buffet": true,
          "DineIn": true,
          "deepDishDisabled": true,
          "autopromise": [],
          "outOfProduct": {
            "productOutages": [],
            "toppingOutages": []
          },
          "internetStatus": [
            {
              "eventTime": "1970-01-01T00:00:00Z",
              "internetStatus": "On",
              "occasion": "delivery",
              "processTime": "1970-01-01T00:00:00Z"
            },
            {
              "eventTime": "1970-01-01T00:00:00Z",
              "internetStatus": "On",
              "occasion": "carryout",
              "processTime": "1970-01-01T00:00:00Z"
            }
          ],
          "info": {
            "address": "203 Wolf Rd",
            "city": "Albany",
            "state": "NY",
            "state_name": "New York",
            "zip": 12205,
            "phone": "(518) 458-1221",
            "status": {
              "online": true,
              "preorder_only": false,
              "status": 3,
              "raw": "T"
            },
            "carryout_open": "10:30AM",
            "carryout_close": "11:00PM",
            "carryout_future_order": false,
            "delivery_open": "10:30AM",
            "delivery_close": "12:00AM",
            "delivery_future_order": false,
            "open_for_delivery": true,
            "open_for_carryout": false,
            "closure_reason": "",
            "onlineStatus": "temp_offline",
            "firstTimeDiscount": false,
            "currentlyOpen": false,
            "deliveryAvailable": false,
            "carryoutAvailable": false,
            "acceptFutureOrders": false,
            "landmark": null,
            "futuredelivery": true,
            "futurecarryout": true,
            "pizzaEvoTestStore": true,
            "offlineStatusMsg": "This store is currently unavailable for online ordering. If you require further assistance, please contact your local store directly by phone at (518) 458-1221.",
            "loyalty": "Y",
            "tmp_offline_msg": "",
            "store_lastUpdate": "",
            "showDeliveryTime": "Y",
            "promiseTimeDel": 35,
            "promiseTimeDelStale": true,
            "showCarryoutTime": "Y",
            "promiseTimeCo": 15,
            "promiseTimeCoStale": true,
            "promiseTime": 35,
            "seq_no": 2
          }
        },
        {
          "address": "1689 Us Route 9",
          "city": "Clifton Park",
          "state": "NY",
          "zip": "12065",
          "country": "US",
          "phone": "(518)371-4400",
          "lat": "42.861743",
          "long": "-73.766466",
          "distance": "11.382",
          "StoreNumber": "034303",
          "OnlineOrdering": true,
          "WingStreet": false,
          "Buffet": false,
          "DineIn": false,
          "deepDishDisabled": true,
          "autopromise": [],
          "outOfProduct": {
            "productOutages": [],
            "toppingOutages": []
          },
          "internetStatus": [
            {
              "eventTime": "1970-01-01T00:00:00Z",
              "internetStatus": "On",
              "occasion": "delivery",
              "processTime": "1970-01-01T00:00:00Z"
            },
            {
              "eventTime": "1970-01-01T00:00:00Z",
              "internetStatus": "On",
              "occasion": "carryout",
              "processTime": "1970-01-01T00:00:00Z"
            }
          ],
          "info": {
            "address": "1689 Us Route 9",
            "city": "Clifton Park",
            "state": "NY",
            "state_name": "New York",
            "zip": 12065,
            "phone": "(518) 371-4400",
            "status": {
              "online": false,
              "preorder_only": false,
              "status": 3,
              "raw": "DC"
            },
            "carryout_open": "10:30AM",
            "carryout_close": "11:00PM",
            "carryout_future_order": false,
            "delivery_open": "10:30AM",
            "delivery_close": "12:00AM",
            "delivery_future_order": false,
            "open_for_delivery": true,
            "open_for_carryout": false,
            "closure_reason": "",
            "onlineStatus": "temp_offline",
            "firstTimeDiscount": false,
            "currentlyOpen": false,
            "deliveryAvailable": false,
            "carryoutAvailable": false,
            "acceptFutureOrders": false,
            "landmark": null,
            "futuredelivery": true,
            "futurecarryout": true,
            "pizzaEvoTestStore": true,
            "offlineStatusMsg": "This store is currently unavailable for online ordering. If you require further assistance, please contact your local store directly by phone at (518) 371-4400.",
            "loyalty": "Y",
            "tmp_offline_msg": "",
            "store_lastUpdate": "",
            "showDeliveryTime": "Y",
            "promiseTimeDel": 35,
            "promiseTimeDelStale": true,
            "showCarryoutTime": "Y",
            "promiseTimeCo": 15,
            "promiseTimeCoStale": true,
            "promiseTime": 35,
            "seq_no": 3
          }
        },
        {
          "address": "N Clark St",
          "city": "Chicago",
          "state": "IL",
          "zip": "60659",
          "country": "US",
          "phone": "(518)371-4400",
          "lat": "42.861743",
          "long": "-73.766466",
          "distance": "11.382",
          "StoreNumber": "034307",
          "OnlineOrdering": true,
          "WingStreet": false,
          "Buffet": false,
          "DineIn": false,
          "deepDishDisabled": true,
          "autopromise": [],
          "outOfProduct": {
            "productOutages": [],
            "toppingOutages": []
          },
          "internetStatus": [
            {
              "eventTime": "1970-01-01T00:00:00Z",
              "internetStatus": "On",
              "occasion": "delivery",
              "processTime": "1970-01-01T00:00:00Z"
            },
            {
              "eventTime": "1970-01-01T00:00:00Z",
              "internetStatus": "On",
              "occasion": "carryout",
              "processTime": "1970-01-01T00:00:00Z"
            }
          ],
          "info": {
            "address": "N Clark St",
            "city": "Chicago",
            "state": "IL",
            "state_name": "New York",
            "zip": 60659,
            "phone": "(518) 371-4400",
            "status": {
              "online": true,
              "preorder_only": true,
              "status": 3,
              "raw": "T"
            },
            "carryout_open": "10:30AM",
            "carryout_close": "11:00PM",
            "carryout_future_order": false,
            "delivery_open": "10:30AM",
            "delivery_close": "12:00AM",
            "delivery_future_order": false,
            "open_for_delivery": true,
            "open_for_carryout": false,
            "closure_reason": "",
            "onlineStatus": "temp_offline",
            "firstTimeDiscount": false,
            "currentlyOpen": false,
            "deliveryAvailable": false,
            "carryoutAvailable": false,
            "acceptFutureOrders": true,
            "landmark": null,
            "futuredelivery": true,
            "futurecarryout": true,
            "pizzaEvoTestStore": true,
            "offlineStatusMsg": "This store is currently unavailable for online ordering. If you require further assistance, please contact your local store directly by phone at (518) 371-4400.",
            "loyalty": "Y",
            "tmp_offline_msg": "",
            "store_lastUpdate": "",
            "showDeliveryTime": "Y",
            "promiseTimeDel": 35,
            "promiseTimeDelStale": true,
            "showCarryoutTime": "Y",
            "promiseTimeCo": 15,
            "promiseTimeCoStale": true,
            "promiseTime": 35,
            "seq_no": 3
          }
        },
        {
          "StoreNumber": "807",
          "lat": "42.861743",
          "long": "-73.766566",
          "info": {
            "address": "2000 Gene Autry Way",
            "city": "Anaheim",
            "state": "CA",
            "state_name": "California",
            "zip": 92806,
            "phone": "(312) 222-1600",
            "status": {
              "online": true,
              "preorder_only": true,
              "status": 3,
              "raw": "T"
            },
            "carryout_open": "10:30AM",
            "carryout_close": "11:00PM",
            "carryout_future_order": false,
            "delivery_open": "10:30AM",
            "delivery_close": "12:00AM",
            "delivery_future_order": false,
            "open_for_delivery": true,
            "open_for_carryout": false,
            "closure_reason": "",
            "onlineStatus": "temp_offline",
            "firstTimeDiscount": false,
            "currentlyOpen": false,
            "deliveryAvailable": false,
            "carryoutAvailable": false,
            "acceptFutureOrders": true,
            "landmark": "Next to ToysRUs Rosewood La",
            "futuredelivery": true,
            "futurecarryout": true,
            "pizzaEvoTestStore": true,
            "offlineStatusMsg": "This store is currently unavailable for online ordering. If you require further assistance, please contact your local store directly by phone at (518) 371-4400.",
            "loyalty": "Y",
            "tmp_offline_msg": "",
            "store_lastUpdate": "",
            "showDeliveryTime": "Y",
            "promiseTimeDel": 35,
            "promiseTimeDelStale": true,
            "showCarryoutTime": "Y",
            "promiseTimeCo": 15,
            "promiseTimeCoStale": true,
            "promiseTime": 35,
            "seq_no": 3
          }
        }
      ]
}
