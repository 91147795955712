interface addressType {
  zipcode?: string;
  city?: string;
  state?: string;
}

export const onRibbonInitialLoad = () => ({
  event_name: 'react_widget_impression',
  screen_name: 'home',
  event_category: 'home',
  event_action: 'localization impression',
  event_label: 'home > localization impression',
  screen_level1: 'home',
  screen_level2: 'home',
  screen_level3: 'home',
  screen_level4: 'home',
  level2_breadcrumb: 'home',
  level3_aggregation: 'home',
  beacon_id: '6.11'
});

export const onRibbonClick = () => ({
  event_name: 'button_click',
  screen_name: 'home',
  event_category: 'home',
  event_action: 'localization click',
  event_label: 'home > localization click',
  screen_level1: 'home',
  screen_level2: 'home',
  screen_level3: 'home',
  screen_level4: 'home',
  level2_breadcrumb: 'home',
  level3_aggregation: 'home',
  beacon_id: '6.12'
});

export const onOpenLocalizationRail = (isSavedAddress: boolean = false) => ({
  event_name: 'screen_view',
  screen_name: 'Localization Widget > Carryout > Store Search Form',
  screen_level1: 'Localization Widget',
  screen_level2: 'Localization Widget > Carryout',
  screen_level3: 'Localization Widget > Carryout > Store Search Form',
  screen_level4: 'Localization Widget > Carryout > Store Search Form',
  level2_breadcrumb: `Localization Widget > Carryout ${isSavedAddress ? '> Saved Address' : ''}`.trim(),
  level3_aggregation: 'Store Search Form',
  form_name: 'Carryout Store Search Form',
  beacon_id: '80.01'
});

export const storeSearchFormAnalytics = (
  occasion: string,
  useGps: boolean = false,
  isSavedAddress: boolean = false,
  closeButton: boolean = false
) => {
  let eventAction;
  let eventLabel;
  if (useGps) {
    eventAction = 'Use my location';
    eventLabel = 'Use GPS';
  } else if (closeButton) {
    eventAction = 'close';
    eventLabel = 'close';
  } else {
    eventAction = occasion;
    eventLabel = occasion;
  }

  return {
    event_name: 'button_click',
    screen_level1: 'Localization Widget',
    screen_name: `Localization Widget > ${occasion} > Store Search Form`,
    event_action: eventAction,
    event_category: `Localization Widget > ${occasion}`,
    event_label: `Localization widget > ${occasion} > ${eventLabel}`,
    screen_level2: `Localization Widget > ${occasion} > Store Search Form`,
    screen_level3: `Localization Widget > ${occasion} > Store Search Form`,
    screen_level4: `Localization Widget > ${occasion} > Store Search Form`,
    level2_breadcrumb: `Localization Widget > ${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim(),
    level3_aggregation: 'Store Search Form',
    beacon_id: '80.02'
  };
};

export const storeSearchSuccessAnalytics = (occasion: string, isSavedAddress: boolean = false) => ({
  event_name: 'button_click',
  screen_name: 'Localization',
  event_action: 'Store Search Success',
  event_category: `Localization Widget > ${occasion}`,
  event_label: `localization widget > ${occasion} > Store Search Success`,
  screen_level1: 'Localization Widget',
  screen_level2: `Localization Widget > ${occasion}`,
  screen_level3: `Localization Widget > ${occasion} > Store Search Form`,
  screen_level4: `Localization Widget > ${occasion} > Store Search Form`,
  level2_breadcrumb: `Localization Widget > ${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim(),
  level3_aggregation: 'Store Search Form',
  form_name: `${occasion} Store Search Form`,
  beacon_id: '80.03'
});

const getStoreSearchTerm = (occasion, address) => {
  if (occasion === 'Delivery') {
    return address.zipcode;
  }
  if (occasion === 'Carryout') {
    if (address.city && address.state) {
      return `${address.city}, ${address.state}`;
    }
    if (address.zipcode) {
      return address.zipcode;
    }
    return 'GPS';
  }
  return undefined;
};

export const storeSearchNoResultsAnalytics = (
  occasion: string,
  address: addressType,
  isSavedAddress: boolean = false
) => ({
  event_name: 'button_click',
  screen_name: `Localization Widget > ${occasion} > Store Search Form`,
  event_action: 'Popup',
  event_category: `Localization Widget > ${occasion} > Store Search No Results`,
  event_label: `Localization Widget > ${occasion} > Store Search No Results > Popup`,
  screen_level1: 'Localization Widget',
  screen_level2: `Localization Widget > ${occasion}`,
  screen_level3: `Localization Widget > ${occasion} > Store Search Form`,
  screen_level4: `Localization Widget > ${occasion} > Store Search Form`,
  level2_breadcrumb: `Localization Widget > ${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim(),
  level3_aggregation: 'Store Search Form',
  store_search_term: getStoreSearchTerm(occasion, address),
  store_search_number_results: '0',
  form_name: `${occasion} Store Search Form`,
  beacon_id: '80.04'
});

export const storeSearchNoResultsActionAnalytics = (
  occasion: string,
  userAction: string,
  isSavedAddress: boolean = false
) => ({
  event_name: 'button_click',
  screen_name: `Localization Widget > ${occasion} > Store Search Form`,
  event_action: userAction,
  event_category: `Localization Widget > ${occasion} > Store Search No Results`,
  event_label: `Localization Widget > ${occasion} > Store Search No Results > ${userAction}`,
  site_type: 'React',
  code_version: 'v1.00',
  screen_level1: 'Localization Widget',
  screen_level2: `Localization Widget > ${occasion}`,
  screen_level3: `Localization Widget > ${occasion} > Store Search Form`,
  screen_level4: `Localization Widget > ${occasion} > Store Search Form`,
  level2_breadcrumb: `Localization Widget > ${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim(),
  level3_aggregation: 'Store Search Form',
  form_name: `${occasion} Store Search Form`,
  beacon_id: '80.05'
});

export const storeResultsAnalytics = (
  occasion: string,
  address: addressType,
  numOfStoreResults: number
) => ({
  event_name: 'screen_view',
  screen_name: `Localization Widget > ${occasion} > Store Search Results`,
  screen_level1: 'Localization Widget',
  screen_level2: `Localization Widget > ${occasion}`,
  screen_level3: `Localization Widget > ${occasion} > Store Search Results`,
  screen_level4: `Localization Widget > ${occasion} > Store Search Results`,
  level2_breadcrumb: `Localization Widget > ${occasion}`,
  level3_aggregation: 'Store Search Results',
  store_search_term: getStoreSearchTerm(occasion, address),
  store_search_number_results: numOfStoreResults.toString(),
  beacon_id: '80.06'
});

export const storeTileCtaAnalytics = (occasion: string) => ({
  event_name: 'button_click',
  event_action: 'Find A Store',
  event_category: 'Localize',
  event_label: 'Localize > Find A Store',
  screen_name: `Localization Widget > ${occasion} > Store Search Results`,
  screen_level1: 'Localization Widget',
  screen_level2: `Localization Widget > ${occasion}`,
  screen_level3: `Localization Widget > ${occasion} > Store Search Results`,
  screen_level4: `Localization Widget > ${occasion} > Store Search Results`,
  level2_breadcrumb: `Localization Widget > ${occasion}`,
  level3_aggregation: 'Store Search Results',
  beacon_id: '80.07'
});

export const storeResultsViewButtonAnalytics = (occasion: string, linkText: string) => ({
  event_name: 'button_click',
  event_action: linkText,
  event_category: 'Localize',
  event_label: `Localize > ${linkText}`,
  screen_name: `Localization Widget > ${occasion} > Store Search Results`,
  screen_level1: 'Localization Widget',
  screen_level2: `Localization Widget > ${occasion}`,
  screen_level3: `Localization Widget > ${occasion} > Store Search Results`,
  screen_level4: `Localization Widget > ${occasion} > Store Search Results`,
  level2_breadcrumb: `Localization Widget > ${occasion}`,
  level3_aggregation: 'Store Search Results',
  beacon_id: '80.08'
});

export const modalErrorPopupAnalytics = (
  modalTitle: string,
  modalMessage: string
) => ({
  event_name: 'error_message',
  event_action: modalTitle,
  event_category: 'Site Pop Up Messages',
  event_label: modalMessage,
  error_message: modalMessage,
  beacon_id: '9.11'
});

export const modalErrorPopupClickAnalytics = (
  modalTitle: string,
  modalMessage: string,
  buttonText: string
) => ({
  event_name: 'error_message',
  event_action: `${modalTitle} > ${buttonText}`,
  event_category: 'Site Pop Up Messages',
  event_label: `${modalMessage} > ${buttonText}`,
  error_message: modalMessage,
  beacon_id: '9.12'
});
