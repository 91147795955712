import dataAnalytics from './dataAnalytics';
import catchAndLogErrors from '../newRelic/catchAndLogErrors';
import { DataAnalytics } from './dataAnalytics.d';

const errorLoggingWrapper : DataAnalytics = {
  push: catchAndLogErrors(dataAnalytics.push),
  pushEventAnalytics: catchAndLogErrors(dataAnalytics.pushEventAnalytics)
};

export default errorLoggingWrapper;
