import React from 'react';
import {
  Button, Grid, makeStyles, Theme, createStyles, AppBar
} from '@material-ui/core';
import CloseIcon from '../icons/CloseIcon';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import dataAnalytics from '../../dataAnalytics';
import { storeResultsViewButtonAnalytics, storeSearchFormAnalytics } from '../../dataAnalytics/dataAnalyticsHelper';

interface RailHeaderProps {
  onClickClose: () => void;
  label: string;
  testId: string;
  icon: React.ReactNode;
  includeSavedAddressInAnalytics: boolean;
}

export const railHeaderHeight = '60px';
export const railHeaderHeightMobileView = '48px';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    padding: '12px',
    fontFamily: 'sharp_sans_bold',
    fontSize: '35px',
    marginBottom: '0',
    width: 'auto',
    height: railHeaderHeight,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '30px',
      height: railHeaderHeightMobileView,
      justifyContent: 'center'
    }
  },
  icon: {
    paddingRight: '15px'
  },
  closeIcon: {
    minWidth: '40px'
  }
}));

export default function RailHeader({
  onClickClose, icon, label, testId, includeSavedAddressInAnalytics
}: RailHeaderProps) {
  const classes = useStyles();

  const onClick = () => {
    onClickClose();
    if (label !== 'Start here') {
      dataAnalytics.push(storeResultsViewButtonAnalytics(label, 'close'));
    } else {
      const occasion = (testId === 'carryout') ? 'Carryout' : 'Delivery';
      dataAnalytics.push(
        storeSearchFormAnalytics(occasion, false, includeSavedAddressInAnalytics, true)
      );
    }
  };

  return (
    <AppBar color="inherit" position="sticky" classes={{ root: classes.root }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <span data-testid={`rail-header-${testId}-icon`} className={classes.icon}>
            {icon}
          </span>
          <span data-testid={`rail-header-${testId}-label`}>
            {label}
          </span>
        </Grid>
        <Grid item>
          <Button
            data-testid="rail-close"
            aria-label="Close store selection"
            onClick={onClick}
            classes={{ root: classes.closeIcon }}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
}
