import React from 'react';

const SvgComponent = (props: any) => (
  <svg width={24} height={24} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 12)">
        <circle cx="7.25" cy="12.5" r="2.5" fill="#CFCECC" />
        <circle cx="25" cy="12.5" r="2.5" fill="#CFCECC" />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M4.386 4.62l2.822-3.57A2.723 2.723 0 019.378 0h10.726a2.83 2.83 0 011.954.798l3.995 3.905 4.973 1.47c.788.215.93.89.974 1.68v3.696c-.042 1.01-.451 1.826-1.495 1.89h-1.963C28.064 14.698 26.63 16 24.938 16c-1.694 0-3.127-1.302-3.604-2.562h-10.57C10.285 14.698 9.031 16 7.337 16c-1.693 0-3.126-1.302-3.604-2.562H1.911C.867 13.375.042 12.558 0 11.548V7.35c.03-.745.53-1.396 1.26-1.638L4.385 4.62zM25 15a2.5 2.5 0 000-5 2.5 2.5 0 000 5zM7.25 15a2.5 2.5 0 000-5 2.5 2.5 0 000 5z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M1.01 11.674c0 .042.303.522.346.522h2.427c.278-1.78 1.648-3.208 3.49-3.208 1.841 0 3.174 1.427 3.453 3.208h10.6c.331-1.724 1.756-3.27 3.55-3.27 1.793 0 3.325 1.546 3.657 3.27h1.813c.474.03.654-.587.654-.63L30.99 7.4l-.646-.409-5.13-1.38a.907.907 0 01-.428-.25L20.67 1.292a1.038 1.038 0 00-.685-.293H9.122c-.301.01-.583.147-.772.377L5.254 5.225l-.385.293L1.25 6.69l-.227.406-.015 4.578z"
        />
      </g>
      <path
        fill="#E71316"
        d="M10.14 5.5L9.061 8.16 3.5 10.558v.281h22v-.281L19.939 8.16 18.86 5.5z"
      />
    </g>
  </svg>
);

export default SvgComponent;
