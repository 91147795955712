import buildStoreDetail from '../../../testUtils/buildStoreDetail';

const mockLocalizationService: LocalizationService = {
  findDeliveryStores: (
    variable: CarryoutSearchDetails
  ) => mockResolvedValueWithMultipleAddressesCases(
    variable,
    {
      stores: [
        buildStoreDetail({ address: '123 Deliver St' })
      ]
    }
  ),
  findDeliveryStoresByLatLong: mockResolvedValue({
    stores: [
      buildStoreDetail({ address: '123 Deliver St' })
    ]
  }),
  findCarryoutStores: (variable: CarryoutSearchDetails) => mockResolvedValueWithFailureCases(
    variable,
    [
      buildStoreDetail({
        storeNumber: '1', address: '101 Carryout', lat: 33.17997, long: -96.886504
      }),
      buildStoreDetail({
        storeNumber: '2', address: '200 Carryout', lat: 33.221533, long: -96.934847
      }),
      buildStoreDetail({
        storeNumber: '3', address: '300 Carryout', lat: 34.221533, long: -96.934847
      }),
      buildStoreDetail({
        storeNumber: '4', address: '400 Carryout', lat: 33.221533, long: -97.934847
      })
    ]
  ),
  selectCarryoutStore: mockResolvedValue(undefined),
  findCarryoutStoresByLatLong: mockResolvedValue([
    buildStoreDetail({ address: '101 Carryout', lat: 33.17997, long: -96.886504 }),
    buildStoreDetail({ address: '200 Carryout', lat: 33.221533, long: -96.934847 })
  ]),
  selectDeliveryStore: mockResolvedValue(undefined)
};

function mockResolvedValueWithMultipleAddressesCases(
  value: any,
  resolvedValue: DeliverySearchResult
): Promise<DeliverySearchResult> {
  if (value.zipcode === '99999') {
    return mockResolvedValue({
      deliveryAddresses: [
        {
          address: '100 Deliver St',
          address2: '#23',
          city: 'Chicago',
          state: 'IL',
          zipcode: '60654',
          lat: -33.4567,
          lng: 77.9898
        },
        {
          address: '200 Deliver St',
          address2: 'A',
          city: 'Chicago',
          state: 'IL',
          zipcode: '60654',
          lat: -34.4567,
          lng: 78.9898
        }
      ]
    })();
  }

  return mockResolvedValue(resolvedValue)();
}

function mockResolvedValueWithFailureCases<T>(value: any, resolvedValue: T): Promise<T> {
  if (value.zipcode === '99999') {
    return new Promise<T>((resolve, reject) => reject());
  }

  return mockResolvedValue(resolvedValue)();
}

function mockResolvedValue<T>(value: T): () => Promise<T> {
  if (typeof (jest) === 'undefined') {
    // When using this mock in our dev server, jest doesn't exist.
    // Handroll a mock instead.
    return () => new Promise((resolve) => resolve(value));
  }

  return jest.fn().mockResolvedValue(value);
}

export default mockLocalizationService;
